import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useIntl } from "gatsby-plugin-react-intl"

import StyledResponsiveWrapper from "./ResponsiveWrapper"
import Button from "./Button"

import {
  getConsentStatus,
  setConsentStatus,
  showConsentManagerService,
} from "../helpers/tracking"
import { media, mixins } from "../styles"

import LoadingAnimation from "../images/icons/loading.inline.svg"

const Thumbnail = styled.div`
  height: 100%;
  opacity: 1;
  position: relative;
  transition: opacity ${({ theme }) => theme.transitionTime};

  > img,
  > svg {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`

const Focus = styled.div`
  background: #000;

  ${(props) =>
    props.isPlaying &&
    css`
      ${Thumbnail} {
        opacity: 0;
      }
    `}
`

const Iframe = styled.iframe`
  background: #000 center center no-repeat;
  background-size: cover;
  z-index: 5;
`

const ConsentNotice = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 5px;

  h3 {
    ${mixins.title5}

    @media ${media.smDown} {
      line-height: 1.1;
      margin: 0;
    }
  }

  p {
    ${mixins.copySmall}

    @media ${media.smDown} {
      line-height: 1.1;
      margin: 0 0 10px;
    }
  }

  @media ${media.smDown} {
    .button {
      font-size: 12px;
    }
  }
`

const ConsentImage = styled.div`
  filter: blur(8px);
  height: 110%;
  pointer-events: none;
  position: absolute;
  width: 110%;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const ConsentContent = styled.div`
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  background: #fff;
  padding: 10px 5px;
  max-width: 600px;
  width: 100%;
  overflow: auto;

  @media ${media.sm} {
    padding: 30px;
  }
`

const ButtonRow = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;

  @media ${media.sm} {
    gap: 30px;
  }
`

const Loading = styled.div`
  color: ${({ theme }) => theme.white};
  z-index: 1;
  position: relative;
`

const ResponsiveWrapper = styled(StyledResponsiveWrapper)`
  margin: 0 0 20px;
`

const Player = ({ isPlaying, youtubeId }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [consent, setConsent] = useState(false)

  const setAllowed = async () => {
    try {
      const isAllowed = await setConsentStatus("youtube")
      setConsent(isAllowed)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // Since we can't trigger anything from the Usercentrics Consent Manager
    // ourselves, we need to check the consent status regularly (1x/sec)
    const checkAllowed = setInterval(async () => {
      const isAllowed = await getConsentStatus("youtube")
      if (isAllowed) {
        setConsent(isAllowed)
        clearInterval(checkAllowed)
      }
    }, 1000)

    return () => {
      clearInterval(checkAllowed)
    }
  }, [])

  useEffect(() => {
    async function showVideo() {
      try {
        const isAllowed = await getConsentStatus("youtube")
        setConsent(isAllowed)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    showVideo()
  }, [getConsentStatus])

  if (loading) {
    return (
      <ConsentNotice>
        <Loading>
          <LoadingAnimation />
        </Loading>
        <ConsentImage>
          <img
            src={`https://privacy-proxy-server.usercentrics.eu/video/youtube/${youtubeId}-poster-image`}
            alt="YouTube"
          />
        </ConsentImage>
      </ConsentNotice>
    )
  }

  if (!consent) {
    return (
      <ConsentNotice>
        <ConsentContent>
          <h3>{intl.formatMessage({ id: "embeds.youtube.consent.header" })}</h3>
          <p>{intl.formatMessage({ id: "embeds.youtube.consent.text" })}</p>

          <ButtonRow>
            <Button onClick={() => showConsentManagerService("youtube")}>
              {intl.formatMessage({
                id: "embeds.youtube.consent.moreInfoButton",
              })}
            </Button>
            <Button kind="primary" onClick={setAllowed}>
              {intl.formatMessage({ id: "embeds.youtube.consent.allowButton" })}
            </Button>
          </ButtonRow>
        </ConsentContent>
        <ConsentImage>
          <img
            src={`https://privacy-proxy-server.usercentrics.eu/video/youtube/${youtubeId}-poster-image`}
            alt="YouTube"
          />
        </ConsentImage>
      </ConsentNotice>
    )
  }

  return (
    <Iframe
      allow={`${isPlaying ? "autoplay; " : ""}encrypted-media`}
      style={{
        backgroundImage: `url(https://privacy-proxy-server.usercentrics.eu/video/youtube/${youtubeId}-poster-image)`,
      }}
      title="YouTube"
      allowFullScreen
      frameBorder="0"
      height="315"
      src={`https://www.youtube-nocookie.com/embed/${youtubeId}?rel=0&amp;showinfo=0${
        isPlaying ? "&amp;autoplay=1" : ""
      }&amp;modestbranding=1`}
      width="560"
    />
  )
}

const YouTubePlayer = (props) => {
  const youtubeIdRegex = /(?:v=|embed\/)([\w-]+)(?:&|$)/
  const match = props.url.match(youtubeIdRegex)
  const youtubeId = match ? match[1] : null

  const [isPlaying, setPlaying] = useState(false)

  useEffect(() => {
    if (props.isPlaying === false) {
      setPlaying(props.isPlaying)
    }
  }, [props])

  useEffect(() => {
    setPlaying(props.isPlaying)
  }, [props])

  return youtubeId ? (
    <ResponsiveWrapper>
      {isPlaying || !props.thumbnail ? (
        <Player isPlaying={isPlaying} youtubeId={youtubeId} />
      ) : null}
      {props.thumbnail ? (
        <Focus
          onClick={() => setPlaying(true)}
          onKeyPress={() => setPlaying(true)}
          role="button"
          tabIndex={0}
          isPlaying={isPlaying}
        >
          <Thumbnail>{props.thumbnail}</Thumbnail>
        </Focus>
      ) : null}
    </ResponsiveWrapper>
  ) : null
}

YouTubePlayer.propTypes = {
  url: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool,
  thumbnail: PropTypes.object,
}

export default YouTubePlayer
