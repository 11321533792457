import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-react-intl"

import { Container, Row, Col } from "../components/Grid"
import Snippet from "../components/Snippet"
import Heading from "../components/Heading"
import Link from "../components/Link"
import StyledBox from "../components/Box"
import ImageContentful from "../components/ImageContentful"

import { media, mixins } from "../styles"

const Wrapper = styled.div`
  padding: 60px 0 70px;
`
const Header = styled.div`
  margin: 0 0 60px;
  text-align: center;
`

const MediaCoverageList = styled.div`
  margin-top: 40px;
`

const Box = styled(StyledBox)`
  border-radius: 7px;
  overflow: hidden;
  word-break: break-word;
`

const MediaCoverage = styled(Link)`
  color: inherit;
  display: block;
  height: calc(100% - 20px);
  margin: 0 0 20px;

  @media ${media.sm} {
    height: calc(100% - 30px);
    margin: 0 0 30px;
  }

  h3 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    margin: 0 !important;
    overflow: hidden;
    transition: color ${({ theme }) => theme.transitionTime};
  }

  &:hover {
    color: inherit;

    ${Box} {
      box-shadow: ${({ theme }) =>
        theme.name === "dark"
          ? `inset 0 0 0 1px ${theme.borderColor}`
          : theme.boxShadowMediumHover} !important;
    }

    h3 {
      color: ${({ theme }) => theme.brandPrimaryDark};
    }
  }
`

const Metadata = styled.p`
  ${mixins.copySmall}
  color: ${({ theme }) => theme.brandSecondaryMedium};
  margin: 0;
`

const Logo = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.brandSecondaryLight};
  display: flex;
  height: 160px;
  justify-content: center;
  padding: 20px;

  @media ${media.md} {
    height: 220px;
  }

  .gatsby-image-wrapper,
  .image--svg,
  svg {
    height: auto;
    max-width: 80%;
    width: 260px;
  }
`

const Content = styled.div`
  padding: 10px 15px 20px;

  @media ${media.lg} {
    height: 145px;
  }
`

const MediaCoverages = () => {
  const intl = useIntl()

  const { allContentfulMediaCoverage } = useStaticQuery(
    graphql`
      query {
        allContentfulMediaCoverage(sort: { fields: date, order: DESC }) {
          nodes {
            date(formatString: "DD.MM.YYYY")
            headline
            node_locale
            publicationLogo {
              ...ContentfulImageData
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 260, layout: CONSTRAINED)
                }
              }
            }
            publicationName
            url
          }
        }
      }
    `
  )

  const mediaCoverage = allContentfulMediaCoverage.nodes.filter(
    (item) => item.node_locale === intl.locale
  )

  return (
    <Wrapper>
      <Container>
        <Header>
          <Heading type="h2" className="h3">
            <Snippet id="5AIQ9yJcFcHn3FXjxJyWpp" field="header" />
          </Heading>
          <p>
            <Snippet id="5AIQ9yJcFcHn3FXjxJyWpp" field="text" />
          </p>
        </Header>

        <MediaCoverageList>
          <Row>
            {mediaCoverage.map((item, i) => (
              <Col sm="6" lg="4" key={i}>
                <MediaCoverage to={item.url}>
                  <Box>
                    <Logo>
                      <ImageContentful
                        alt={item.publicationName}
                        image={item.publicationLogo}
                      />
                    </Logo>
                    <Content>
                      <Metadata>
                        {item.publicationName} · {item.date}
                      </Metadata>
                      <Heading type="h3" className="h4">
                        {item.headline}
                      </Heading>
                    </Content>
                  </Box>
                </MediaCoverage>
              </Col>
            ))}
          </Row>
        </MediaCoverageList>
      </Container>
    </Wrapper>
  )
}

export default MediaCoverages
