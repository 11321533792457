import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Media from "react-media"

import { Container as StyledContainer } from "../components/Grid"
import ImageContentful from "../components/ImageContentful"
import { media } from "../styles"

const Container = styled(StyledContainer)`
  margin-bottom: 30px;
  padding: ${({ theme }) => theme.spacingXs};

  @media ${media.md} {
    margin-bottom: 80px;
    padding: 80px 0;
  }

  img {
    display: block;
    max-width: 320px;
    margin: 0 auto;
    width: 100%;

    @media ${media.sm} {
      max-width: 770px;
    }

    @media ${media.md} {
      max-width: 1230px;
    }
  }
`

export const OrgChart = () => {
  const data = useStaticQuery(graphql`
    query {
      orgchartMobile: contentfulAsset(
        contentful_id: { eq: "6MsRH0SqtKZDESHwq1Tz3J" }
      ) {
        ...ContentfulImageData
      }
      orgchartTablet: contentfulAsset(
        contentful_id: { eq: "7C4DiVsTQv6tEBaccDoYKi" }
      ) {
        ...ContentfulImageData
      }
      orgchartDesktop: contentfulAsset(
        contentful_id: { eq: "4xcytXxUbBPfxUfPbkrxUi" }
      ) {
        ...ContentfulImageData
      }
    }
  `)

  return (
    <Container>
      <Media
        queries={{
          mobile: "(max-width: 599px)",
          tablet: "(min-width: 600px) and (max-width: 1079px)",
          desktop: "(min-width: 1080px)",
        }}
      >
        {(matches) => (
          <>
            {matches.mobile && (
              <ImageContentful image={data.orgchartMobile} type="svg" />
            )}
            {matches.tablet && (
              <ImageContentful image={data.orgchartTablet} type="svg" />
            )}
            {matches.desktop && (
              <ImageContentful image={data.orgchartDesktop} type="svg" />
            )}
          </>
        )}
      </Media>
    </Container>
  )
}

export default OrgChart
