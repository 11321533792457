import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-react-intl"

import { Container } from "../components/Grid"
import Snippet from "../components/Snippet"
import Button from "../components/Button"

import { media } from "../styles"

const Wrapper = styled.div`
  padding: 60px 0 40px;
`

const PressReleaseList = styled.div`
  margin-top: 40px;
`

const PressRelease = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.brandSecondaryLight};
  margin-bottom: 35px;
  padding-bottom: 27px;

  h4 {
    color: ${({ theme }) => theme.brandPrimaryDark};
  }
`

const Content = styled.div`
  @media ${media.sm} {
    display: flex;
    flex-flow: row nowrap;
  }
`

const Summary = styled.div``

const Date = styled.div`
  color: ${({ theme }) => theme.brandSecondaryMedium};
  margin-bottom: 5px;
`

const ButtonCol = styled.div`
  margin: 20px 0 0;
  width: 180px;

  @media ${media.sm} {
    align-self: flex-start;
    flex: none;
    margin: 0 0 0 30px;
  }
`

const FileLink = styled.a`
  display: block;

  > span {
    display: block;
  }
`

const Filter = styled.div`
  margin: 20px 0 50px;
`

const FilterItem = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: #b6b6b6;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 0 10px 0 0;
  outline: none;
  padding: 0;

  ${(props) =>
    props.active &&
    css`
      color: ${({ theme }) => theme.brandSecondaryMedium};
      cursor: default;
    `}
`

const PressReleases = () => {
  const intl = useIntl()
  const [year, setYear] = useState(null)

  const { allContentfulPressRelease } = useStaticQuery(
    graphql`
      query {
        allContentfulPressRelease(sort: { fields: date, order: DESC }) {
          nodes {
            title
            summary {
              summary
            }
            # file {
            #   file {
            #     fileName
            #   }
            # }
            node_locale
            date(formatString: "DD.MM.YYYY")
            year: date(formatString: "YYYY")
          }
        }
      }
    `
  )

  const pressReleases = allContentfulPressRelease.nodes.filter(
    (pressRelease) => pressRelease.node_locale === intl.locale
  )

  const allYears = []
  pressReleases.map((pr) => allYears.push(pr.year))
  const filterYears = Array.from(new Set(allYears))

  const filteredPressReleases = pressReleases.filter(
    (pr) => year === null || pr.year === year
  )

  return (
    <Wrapper>
      <Container>
        <h3>
          <Snippet id="1Ngxv8025NjeafxxshpwOi" field="header" />
        </h3>

        {filterYears.length > 1 && (
          <Filter>
            <FilterItem active={year === null} onClick={() => setYear(null)}>
              All
            </FilterItem>
            {filterYears.map((filteredYear) => (
              <FilterItem
                active={year === filteredYear}
                key={filteredYear}
                onClick={() => setYear(filteredYear)}
              >
                {filteredYear}
              </FilterItem>
            ))}
          </Filter>
        )}

        <PressReleaseList>
          {filteredPressReleases.map((pressRelease) => (
            <PressRelease key={pressRelease.title}>
              <Date>{pressRelease.date}</Date>
              <h4>{pressRelease.title}</h4>
              <Content>
                <Summary>
                  <p>{pressRelease.summary.summary}</p>
                </Summary>
                <ButtonCol>
                  {pressRelease.file && (
                    <FileLink
                      href={`/documents/press-releases/${pressRelease.file.file.fileName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button as="span" kind="outline" arrow>
                        <Snippet id="f8YeS8vetfXUK9HXGeEAH" field="linkText" />
                      </Button>
                    </FileLink>
                  )}
                </ButtonCol>
              </Content>
            </PressRelease>
          ))}
        </PressReleaseList>
      </Container>
    </Wrapper>
  )
}

export default PressReleases
