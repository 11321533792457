import React from "react"
import styled from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import Heading from "../components/Heading"
import Markdown from "../components/Markdown"
import Button from "../components/Button"

import { media, mixins } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 50px;
  padding-top: 50px;

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.brandPrimaryDark};
  color: ${({ theme }) => theme.brandPrimaryLight};
  padding: 50px 25px;
  text-align: center;

  @media ${media.tabletLandscape} {
    padding: 75px 0;
  }

  p {
    a {
      color: ${({ theme }) => theme.brandPrimaryMedium};
    }
  }

  .button {
    &:hover {
      background: ${({ theme }) => theme.brandLinksHover};
      color: ${({ theme }) => theme.brandPrimaryLight};
    }
  }
`

const Header = styled(Heading).attrs((props) => ({ type: "h3" }))`
  color: ${({ theme }) => theme.brandPrimaryLight};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.lg} {
    max-width: 900px;
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: 20px;

  .button {
  }

  @media ${media.sm} {
    margin-top: 40px;
  }
`

const ExtraText = styled.div`
  margin: 20px auto 10px;

  @media ${media.lg} {
    margin: 20px 0 0;
  }

  p {
    ${mixins.copySmall};
    line-height: 1;
  }

  small {
    color: inherit;
  }
`

const Content = styled.div`
  @media ${media.lg} {
    padding: 0 30px;
  }
`

const CTA = ({ header, text, linkText, linkUrl, extraText, source }) => {
  return (
    <Container>
      <Wrapper>
        <Row>
          <Col md={8} mdOffset={2} xl={6} xlOffset={3}>
            <Content>
              {header && <Header>{header}</Header>}
              {text && (
                <Markdown>
                  {source === "contentful" ? text.text : text}
                </Markdown>
              )}
              {linkText && linkUrl && (
                <ButtonRow>
                  <Button href={linkUrl} kind="secondary" size="large">
                    {linkText}
                  </Button>
                </ButtonRow>
              )}
              {extraText && (
                <ExtraText>
                  <Markdown>
                    {source === "contentful" ? extraText.extraText : extraText}
                  </Markdown>
                </ExtraText>
              )}
            </Content>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  )
}

export default CTA
