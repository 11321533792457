import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../Box"
import { Container as StyledContainer, Row, Col } from "../Grid"
import StyledHeading from "../Heading"
import TextStyles from "../TextStyles"
import Snippet from "../Snippet"
import ImageContentful from "../ImageContentful"

import { media } from "../../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 20px;
  padding-top: 20px;

  @media ${media.tablet} {
    padding-top: 45px;
  }

  @media ${media.tabletLandscape} {
    padding-top: 60px;
    padding-bottom: 0;
  }

  @media ${media.desktop} {
    padding-top: 85px;
    padding-bottom: 15px;
  }
`

const BenefitsWrapper = styled.div`
  /* padding-bottom: 40px;
  padding-top: 40px; */
`

const Benefit = styled(Box)`
  padding: 30px 25px 35px;
  margin: 0 0 30px;
  min-height: calc(100% - 30px);

  ul {
    padding-left: 18px;
  }

  @media ${media.tabletLandscape} {
    padding: 30px 35px 35px;
  }
`

const BenefitHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
  min-height: 56px;

  @media ${media.xl} {
    margin-bottom: 25px;
    min-height: 0;
  }
`

const Icon = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.brandPrimaryDark};
  display: flex;
  flex: none;
  height: 30px;
  margin-right: 20px;
  width: 32px;

  ${(props) =>
    props.wide &&
    css`
      margin-right: 15px;
      width: 40px;
    `}

  .wrap--svg {
    display: block;
    width: 100%;

    svg {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }
`

const Heading = styled(StyledHeading)`
  margin: 0;
`

const institutionsBenefitsText1 = "7bc0jGOpRVhGPGQrv3HMBP"
const institutionsBenefitsText2 = "5aM4WUJkcN1izik67gDpT6"
const institutionsBenefitsText3 = "2p9FI0WsfKrQECub7mkDhY"

const iconList = "4O0Jy1EQWeZnZ3huPojVxO"
const iconCode = "2HsmlWOHEQPnDPKxAlikaO"
const iconShield = "7px8Zh2Cm0bL9Hw3AbqO6T"

const Benefits = () => {
  const data = useStaticQuery(graphql`
    query {
      iconList: contentfulAsset(
        contentful_id: { eq: "4O0Jy1EQWeZnZ3huPojVxO" }
      ) {
        ...ContentfulImageData
      }
      iconCode: contentfulAsset(
        contentful_id: { eq: "2HsmlWOHEQPnDPKxAlikaO" }
      ) {
        ...ContentfulImageData
      }
      iconShield: contentfulAsset(
        contentful_id: { eq: "7px8Zh2Cm0bL9Hw3AbqO6T" }
      ) {
        ...ContentfulImageData
      }
    }
  `)

  return (
    <Container>
      <BenefitsWrapper>
        <TextStyles textColor="light" textSize="small">
          <Row justifyContent="center">
            <Col sm={6} lg={4}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true">
                    <ImageContentful alt="" image={data.iconList} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={institutionsBenefitsText1} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet
                  id={institutionsBenefitsText1}
                  field="text"
                  renderHtml
                />
              </Benefit>
            </Col>
            <Col sm={6} lg={4}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true" wide>
                    <ImageContentful alt="" image={data.iconCode} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={institutionsBenefitsText2} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet
                  id={institutionsBenefitsText2}
                  field="text"
                  renderHtml
                />
              </Benefit>
            </Col>
            <Col sm={6} lg={4}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true">
                    <ImageContentful alt="" image={data.iconShield} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={institutionsBenefitsText3} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet
                  id={institutionsBenefitsText3}
                  field="text"
                  renderHtml
                />
              </Benefit>
            </Col>
          </Row>
        </TextStyles>
      </BenefitsWrapper>
    </Container>
  )
}

export default Benefits
