import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

import { Container as StyledContainer } from "../components/Grid"
import ImageContentful from "../components/ImageContentful"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding: ${({ theme }) => `${theme.spacingXs * 2}px 0 ${theme.spacingXs}px`};

  @media ${media.sm} {
    margin-top: ${({ theme }) => `-${theme.spacingXs}px`};
    padding: ${({ theme }) => `0 0 ${theme.spacing * 1.5}px`};
  }
`

const Seals = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0 12.5px;

  @media ${media.sm} {
    padding: 30px ${({ theme }) => theme.spacing}px;
  }

  @media ${media.lg} {
    padding: 0;
  }
`

const Seal = styled.li`
  align-items: center;
  display: flex;
  height: 125px;
  margin: 0 0 40px;
  max-width: 50%;
  width: 180px;

  @media ${media.sm} {
    margin: 0 7.5px 60px;
    max-width: 200px;
    width: calc(21% - 15px);
  }

  @media ${media.lg} {
    width: calc(14.2857142857% - 15px);
  }

  a {
    height: 100%;
    margin: 0 auto;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  img {
    max-height: 140px;
  }
`

export const TrustSeals = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      beginners: allContentfulAsset(
        filter: { contentful_id: { eq: "5OQlUuXBXawbHSF9YCbkax" } }
      ) {
        nodes {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 330, layout: CONSTRAINED)
            }
          }
        }
      }
      computerBild1: allContentfulAsset(
        filter: { contentful_id: { eq: "4oqllGbKuozUbAR832G2dA" } }
      ) {
        nodes {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 330, layout: CONSTRAINED)
            }
          }
        }
      }
      extraETF: allContentfulAsset(
        filter: { contentful_id: { eq: "6N7kMfnOKSTWBO27X5AzOV" } }
      ) {
        nodes {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 330, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const getLocalizedImage = (image) =>
    data[image].nodes.filter((item) => item.node_locale === intl.locale)[0]

  return (
    <Container>
      <Seals>
        <Seal>
          <a
            href="https://de.extraetf.com/krypto-broker/bsdex-krypto-broker-im-test"
            target="_blank"
            rel="nofollow noopener"
          >
            <ImageContentful
              alt={getLocalizedImage("extraETF")?.description}
              image={getLocalizedImage("extraETF")}
              objectFit="contain"
            />
          </a>
        </Seal>
        <Seal>
          <a
            href="https://www.boerse-online.de/nachrichten/geld-und-vorsorge/kryptobroker-im-test-kryptoboersen-und-plattformen-unter-der-lupe-wer-hat-die-nase-vorn-1030280953"
            target="_blank"
            rel="nofollow noopener"
          >
            <ImageContentful
              alt={getLocalizedImage("beginners")?.description}
              image={getLocalizedImage("beginners")}
              objectFit="contain"
            />
          </a>
        </Seal>
        <Seal>
          <ImageContentful
            alt={getLocalizedImage("computerBild1")?.description}
            image={getLocalizedImage("computerBild1")}
            objectFit="contain"
          />
        </Seal>
      </Seals>
    </Container>
  )
}

export default TrustSeals
