import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container as StyledContainer,
  Row as StyledRow,
  Col as StyledCol,
} from "../../components/Grid"
import StyledHeading from "../../components/Heading"
import ImageContentful from "../../components/ImageContentful"
import Button from "../../components/Button"
import Snippet from "../../components/Snippet"
import Markdown from "../../components/Markdown"

import { media } from "../../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${media.sm} {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .image--svg {
    margin-bottom: 20px;
  }
`

const Row = styled(StyledRow)`
  @media ${media.md} {
    margin-bottom: ${({ theme }) => theme.spacingXs}px;
  }

  @media ${media.xl} {
    margin-bottom: ${({ theme }) => theme.spacing}px;
  }
`

const Col = styled(StyledCol)`
  margin-bottom: ${({ theme }) => theme.spacingXs}px;

  @media ${media.sm} {
    margin-bottom: ${({ theme }) => theme.spacing}px;
  }
`

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;

  @media ${media.sm} {
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${media.md} {
    margin-bottom: 40px;
  }
`

const Heading = styled(StyledHeading)`
  @media ${media.sm} {
    margin-right: ${({ theme }) => theme.spacing}px;
  }
`

const Image = styled.div`
  max-width: 100%;
  margin: 20px auto;
  width: 430px;

  @media ${media.lg} {
    flex: 1 0 auto;
  }

  @media ${media.xl} {
    /* 1 column */
    margin-right: calc(1 / 12 * 100%);
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: 20px;

  @media ${media.sm} {
    margin-top: 40px;
  }
`

const LandingBitcoinAdvantages = (props) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: contentfulAsset(
        contentful_id: { eq: "5jD2mjrog2Sm2FxnxPEDZH" }
      ) {
        ...ContentfulImageData
        localFile {
          childImageSharp {
            gatsbyImageData(width: 445, layout: CONSTRAINED)
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Header>
        <Heading type={props.headerLevel}>
          <Snippet id="76XbkWgz7KL06jsgpIdYKm" field="header" />
        </Heading>
        <Image>
          <ImageContentful image={data.illustration} />
        </Image>
      </Header>
      <Row>
        <Col sm={6} xl={5}>
          <h4>
            <Snippet id="ErKU3MkND4Z6eIGhgeL9z" field="header" />
          </h4>
          <Markdown>
            {Snippet({
              id: "ErKU3MkND4Z6eIGhgeL9z",
              field: "text",
            })}
          </Markdown>
        </Col>
        <Col sm={6} xl={5} xlOffset={1}>
          <h4>
            <Snippet id="1zSFJ5dJrp4Cx5vCJt2T66" field="header" />
          </h4>
          <Markdown>
            {Snippet({
              id: "1zSFJ5dJrp4Cx5vCJt2T66",
              field: "text",
            })}
          </Markdown>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xl={5}>
          <h4>
            <Snippet id="2RNzsI4B372mOiz2BtU0ep" field="header" />
          </h4>
          <Markdown>
            {Snippet({
              id: "2RNzsI4B372mOiz2BtU0ep",
              field: "text",
            })}
          </Markdown>
        </Col>
        <Col sm={6} xl={5} xlOffset={1}>
          <h4>
            <Snippet id="1F3Ase0ZrWQUuycjOmXSRT" field="header" />
          </h4>
          <Markdown>
            {Snippet({
              id: "1F3Ase0ZrWQUuycjOmXSRT",
              field: "text",
            })}
          </Markdown>
        </Col>
      </Row>

      <Row>
        <Col sm={6} xl={5}>
          <h4>
            <Snippet id="5g4zTCv8UHybEzXbs39M3l" field="header" />
          </h4>
          <Markdown>
            {Snippet({
              id: "5g4zTCv8UHybEzXbs39M3l",
              field: "text",
            })}
          </Markdown>
        </Col>
      </Row>

      <ButtonRow>
        <Button
          href={Snippet({
            id: "2bgOEhg31eVziuMscl0LvM",
            field: "linkUrl",
          })}
          kind="primary"
          size="large"
        >
          <Snippet id="2bgOEhg31eVziuMscl0LvM" field="linkText" />
        </Button>
      </ButtonRow>
    </Container>
  )
}

export default LandingBitcoinAdvantages
