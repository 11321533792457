import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ScreenReaderText } from "../components/Accessibility"

const PaginationContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 0 70px;
  width: 100%;

  .link {
    appearance: none;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.brandSecondaryMedium};
    cursor: pointer;
    line-height: 1;
    margin: 0 10px;
    padding: 5px;
    text-decoration: none;
  }

  .active {
    color: ${({ theme }) => theme.brandSecondaryDark};
    cursor: default;
  }

  [disabled] {
    cursor: default;
    opacity: 0.5;
  }
`

const PrevLinkEl = styled.div`
  margin-right: 5px;
`

const NextLinkEl = styled.div`
  margin-left: 5px;
`

export const PrevLink = ({ label, as, ...props }) => {
  return (
    <PrevLinkEl
      title={label}
      rel="prev"
      as={as || Link}
      className="link"
      {...props}
    >
      <ScreenReaderText>{label}</ScreenReaderText>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="12"
        viewBox="0 0 8 12"
      >
        <polygon
          fill="currentColor"
          fillRule="evenodd"
          points="15.41 7.41 14 6 8 12 14 18 15.41 16.59 10.83 12"
          transform="translate(-7.41 -6)"
        />
      </svg>
    </PrevLinkEl>
  )
}

export const NextLink = ({ label, as, ...props }) => {
  return (
    <NextLinkEl
      title={label}
      rel="next"
      as={as || Link}
      className="link"
      {...props}
    >
      <ScreenReaderText>{label}</ScreenReaderText>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="12"
        viewBox="0 0 8 12"
      >
        <polygon
          fill="currentColor"
          fillRule="evenodd"
          points="15.41 7.41 14 6 8 12 14 18 15.41 16.59 10.83 12"
          transform="matrix(-1 0 0 1 16 -6)"
        />
      </svg>
    </NextLinkEl>
  )
}

const Pagination = ({ children, ...props }) => {
  return <PaginationContainer {...props}>{children}</PaginationContainer>
}

export default Pagination
