import React from "react"
import styled from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import Markdown from "../components/Markdown"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"
import Button from "../components/Button"

import { renderContentfulBlocks } from "../helpers/contentful"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`

const Main = styled.div``

const Aside = styled.div``

const AsideBox = styled.div`
  background: ${({ theme }) => theme.brandSecondaryLight};
  padding: ${({ theme }) => `${theme.spacing}px ${theme.spacingXs}px`};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.brandSecondaryMedium};
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: 20px;

  @media ${media.sm} {
    margin-top: 60px;
  }
`

const TextBlockWithAside = (props) => {
  let id

  if (props.anchorId) {
    id = {
      id: props.anchorId,
    }
  }

  return (
    <div
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
      {...id}
    >
      <Container>
        <Row>
          <Col sm={6} md={7} lg={8}>
            <Main>
              <TextStyles
                alignment={props.alignment}
                textColor={props.textColor}
                textSize={props.textSize}
              >
                {props.header && (
                  <Heading
                    type={props.headerLevel}
                    className={props.headerColor}
                  >
                    {props.header}
                  </Heading>
                )}
                {props.subheader && (
                  <Heading
                    type={props.subheaderLevel}
                    subHeader
                    className={props.subheaderColor}
                  >
                    {props.subheader}
                  </Heading>
                )}
                {props.text &&
                  (props.source === "contentful" ? (
                    renderContentfulBlocks(props.text, {
                      ...props,
                    })
                  ) : (
                    <Markdown>{props.text}</Markdown>
                  ))}
                {props.linkText && props.linkUrl && (
                  <ButtonRow>
                    <Button href={props.linkUrl} kind="primary" size="large">
                      {props.linkText}
                    </Button>
                  </ButtonRow>
                )}
              </TextStyles>
            </Main>
          </Col>
          {props.aside && (
            <Col sm={6} md={5} lg={4}>
              <Aside>
                <AsideBox className={`text-small`}>
                  <Markdown>{props.aside.aside}</Markdown>
                </AsideBox>
              </Aside>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default TextBlockWithAside
