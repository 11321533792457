import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

import { Container, Row, Col } from "../components/Grid"
import Markdown from "../components/Markdown"
import Heading from "../components/Heading"
import Link from "../components/Link"
import StyledBox from "../components/Box"
import ButtonStyled from "../components/Button"
import Snippet from "../components/Snippet"
import { slugify, truncate } from "../helpers"

import { media, mixins } from "../styles"
import ArrowSVG from "../images/icons/arrow-right.inline.svg"

const Wrapper = styled.div`
  padding: 40px 0;

  h2 {
    margin-bottom: 55px;
  }

  h3 {
    margin-bottom: 30px;

    @media ${media.sm} {
      margin-bottom: 90px;
    }
  }
`

const ItemRow = styled(Row)`
  @media ${media.sm} {
    margin-bottom: 10px;
  }

  @media ${media.xl} {
    margin-bottom: 30px;
  }
`

const Header = styled.div`
  margin-bottom: 30px;
  text-align: center;

  @media ${media.md} {
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 10px;
  }
`

const Box = styled(StyledBox)`
  border-radius: 7px;
  display: flex;
  flex-flow: column;
  margin: 0 0 20px;
  padding: 35px 25px;
  word-break: break-word;

  @media ${media.sm} {
    margin: 0 0 30px;
    min-height: calc(100% - 30px);
  }

  @media ${media.lg} {
    padding: 45px 35px;
  }
`

const Item = styled(Link)`
  color: inherit;
  ${mixins.textLight}

  h4,
  p {
    width: 100%; /* IE 11 */
  }

  h4 {
    transition: color ${({ theme }) => theme.transitionTime};
  }

  p {
    ${mixins.copySmall}
    margin-bottom: 20px;
  }

  &:hover {
    color: inherit;

    ${Box} {
      box-shadow: ${({ theme }) =>
        theme.name === "dark"
          ? `inset 0 0 0 1px ${theme.borderColor}`
          : theme.boxShadowMediumHover} !important;
    }

    h4 {
      color: ${({ theme }) => theme.brandPrimaryDark};
    }
  }
`

const ItemHighlight = styled(Item)`
  ${Box} {
    background: ${({ theme }) => theme.brandSecondaryLight};
  }
`

const Button = styled(ButtonStyled)`
  display: table;
  margin: 0 auto;

  @media ${media.md} {
    max-width: 240px;
  }
`

const ReadMore = styled.span`
  ${mixins.copySmall};
  color: ${({ theme }) => theme.brandPrimaryDark};
  margin: auto 0 0 auto;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;

    @media ${media.lg} {
      margin-left: 10px;
    }
  }
`

const faqSecurityTeaser = "cpaxdoOqGessVVE8um4b6"

const FAQTopThree = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaqCollection(
        filter: { contentful_id: { eq: "6Glaj6R43y5Nnhdr1c0ii9" } }
        sort: { fields: sortOrder }
      ) {
        nodes {
          contentful_id
          title
          faqs {
            question
            contentful_id
            answer {
              answer
            }
            faqcollection {
              title
              contentful_id
            }
            node_locale
          }
          node_locale
          title
        }
      }
    }
  `)

  const faqs = data.allContentfulFaqCollection.nodes.filter(
    (node) => node.node_locale === intl.locale
  )[0]?.faqs

  const getCollectionId = (collections) => {
    return collections.find(
      (collection) => collection.contentful_id !== "6Glaj6R43y5Nnhdr1c0ii9"
    )?.title
  }

  return (
    faqs?.length >= 1 && (
      <Wrapper>
        <Container>
          <Header>
            <Heading type="h2" className="h3">
              <Snippet id="3cyvxKJ1Xo8A6Fe1axjbYj" field="header" />
            </Heading>
            <p>
              <Snippet id="a6vK1mhNcDyPg03xgbFPw" field="header" />
            </p>
          </Header>
          <Row>
            <Col lg="10" lgOffset="1" xl="8" xlOffset="2">
              <ItemRow>
                {faqs.map((faq) => (
                  <Col sm={6} key={faq.contentful_id}>
                    <Item
                      to={`/faq/#${slugify(
                        `${getCollectionId(faq.faqcollection)}-${faq.question}`
                      )}`}
                    >
                      <Box>
                        <h4>{faq.question}</h4>
                        <Markdown>{truncate(faq.answer.answer, 120)}</Markdown>
                        <ReadMore>
                          <span>{intl.formatMessage({ id: "readMore" })}</span>
                          <ArrowSVG />
                        </ReadMore>
                      </Box>
                    </Item>
                  </Col>
                ))}
                <Col sm={6}>
                  <ItemHighlight
                    to={Snippet({ id: faqSecurityTeaser, field: "linkUrl" })}
                    intl={false}
                  >
                    <Box>
                      <h4>
                        <Snippet id={faqSecurityTeaser} field="header" />
                      </h4>
                      <Snippet
                        id={faqSecurityTeaser}
                        field="text"
                        renderHtml={true}
                      />
                      <ReadMore>
                        <span>
                          <Snippet id={faqSecurityTeaser} field="linkText" />
                        </span>
                        <ArrowSVG />
                      </ReadMore>
                    </Box>
                  </ItemHighlight>
                </Col>
              </ItemRow>
            </Col>
          </Row>
          <Button kind="primary" size="large" href={`/faq/`}>
            <Snippet id="6eK0LxlWiDEjfLUytCj4t6" field="linkText" />
          </Button>
        </Container>
      </Wrapper>
    )
  )
}

export default FAQTopThree
