import React from "react"
import styled, { css } from "styled-components"

import {
  Container as StyledContainer,
  Row as StyledRow,
  Col,
} from "../components/Grid"
import TextStyles from "../components/TextStyles"
import Markdown from "../components/Markdown"
import Heading from "../components/Heading"
import ImageContentful from "../components/ImageContentful"

import { renderContentfulBlocks } from "../helpers/contentful"
import { media } from "../styles"

const Wrapper = styled.div`
  overflow: hidden;
`

const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  ${(props) =>
    props.hasNoPadding &&
    css`
      padding: 0 !important;
    `}
`

const Row = styled(StyledRow)`
  @media ${media.sm} {
    flex-wrap: nowrap;
  }
`

const ImageCol = styled(Col)`
  margin-bottom: 30px;

  @media ${media.lg} {
    margin-bottom: 0;

    ${(props) =>
      props.imageAlignment === "Image Left" &&
      css`
        padding-right: 35px;
      `}

    ${(props) =>
      props.imageAlignment === "Image Left Outset" &&
      css`
        margin-left: -16.6666666667%;
        margin-right: 8.33333333333%;
      `}

    ${(props) =>
      props.imageAlignment === "Image Right" &&
      css`
        padding-left: 35px;
      `}

      ${(props) =>
      props.imageAlignment === "Image Right Outset" &&
      css`
        margin-right: -16.6666666667%;
        margin-left: 8.33333333333%;
      `}
  }
`

const Content = styled(Col)`
  margin-bottom: 20px;

  @media ${media.md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`

const TextAndImageBlock = (props) => {
  return (
    <Wrapper
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
    >
      <TextStyles
        alignment={props.alignment}
        textColor={props.textColor}
        textSize={props.textSize}
      >
        <Container hasNoPadding={props.layout === "no padding"}>
          <Row>
            <Content
              order={
                props.imageAlignment === "Image Left" ||
                props.imageAlignment === "Image Left Outset"
                  ? 2
                  : 1
              }
              sm={6}
            >
              {props.header && (
                <Heading
                  type={props.headerLevel || "h4"}
                  className={props.headerColor}
                >
                  {props.header}
                </Heading>
              )}

              {props.source === "contentful" ? (
                renderContentfulBlocks(props.text, { ...props })
              ) : (
                <Markdown>{props.text}</Markdown>
              )}
            </Content>
            {props.image && (
              <ImageCol
                imageAlignment={props.imageAlignment}
                order={
                  props.imageAlignment === "Image Left" ||
                  props.imageAlignment === "Image Left Outset"
                    ? 1
                    : 2
                }
                lg={
                  props.imageAlignment === "Image Left Outset" ||
                  props.imageAlignment === "Image Right Outset"
                    ? 7
                    : 6
                }
              >
                <ImageContentful
                  alt={props.image.description || props.header}
                  image={props.image}
                />
              </ImageCol>
            )}
          </Row>
        </Container>
      </TextStyles>
    </Wrapper>
  )
}

export default TextAndImageBlock
