import React from "react"
import styled from "styled-components"

import { Container, Row, Col } from "../components/Grid"
import Markdown from "../components/Markdown"
import Heading from "../components/Heading"
import ImageContentful from "../components/ImageContentful"
import StyledButton from "../components/Button"

import { media } from "../styles"

const StageWrapper = styled.div`
  background: ${({ theme }) => theme.brandPrimaryDark};
`

const StageContent = styled.div`
  padding: 30px 0;

  @media ${media.sm} {
    padding: 30px 35px;
    text-align: center;
  }

  @media ${media.md} {
    padding: 30px 0;
  }

  @media ${media.lg} {
    padding: 40px 0;
  }

  h1 {
    color: ${({ theme }) => theme.brandPrimaryLight};
    margin-bottom: 5px;

    @media ${media.sm} {
      margin-bottom: 20px;
    }

    @media ${media.md} {
      margin-bottom: 25px;
    }

    @media ${media.lg} {
      margin-bottom: 30px;
    }

    @media ${media.xl} {
      margin-bottom: 35px;
    }
  }

  p,
  ul,
  ol {
    color: ${({ theme }) => theme.brandPrimaryLight};
    line-height: 1.5;
    letter-spacing: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StageImage = styled.div`
  padding: 10px 0 0;

  @media ${media.md} {
    padding: 10px 40px 0;
  }
`

const ImageEl = styled.div`
  margin: 0 auto;
  max-width: 675px;
`

const Button = styled(StyledButton)`
  margin: 30px 0 0;

  @media ${media.sm} {
    margin-top: 20px;
  }

  @media ${media.md} {
    margin-top: 30px;
  }

  @media ${media.lg} {
    margin-top: 40px;
  }

  @media ${media.xl} {
    margin-top: 60px;
  }
`

const FinePrint = styled.div`
  color: ${({ theme }) => theme.brandPrimaryLight};
  margin-top: 15px;

  p {
    font-size: inherit;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.brandPrimaryLight};

    &:hover {
      color: ${({ theme }) => theme.brandPrimaryMedium};
    }
  }
`

const Stage = (props) => {
  return (
    <StageWrapper>
      <Container>
        <Row>
          <Col xl={10} xlOffset={1}>
            <StageContent>
              {props.header && (
                <Heading type="h1" className="h2">
                  {props.header}
                </Heading>
              )}
              {props.text && (
                <div>
                  <Markdown>{props.text.text}</Markdown>
                </div>
              )}
              {props.buttonText && props.buttonUrl && (
                <Button
                  href={props.buttonUrl}
                  kind="secondary-outline-inverted"
                  size="large"
                >
                  {props.buttonText}
                </Button>
              )}
              {props.finePrint && (
                <FinePrint>
                  <small>
                    <Markdown>{props.finePrint.finePrint}</Markdown>
                  </small>
                </FinePrint>
              )}
            </StageContent>
          </Col>
        </Row>

        {props.image && (
          <Row>
            <Col sm={8} smOffset={2} md={10} mdOffset={1}>
              <StageImage>
                <ImageEl>
                  <ImageContentful
                    alt={props.image.description || props.header}
                    image={props.image}
                  />
                </ImageEl>
              </StageImage>
            </Col>
          </Row>
        )}
      </Container>
    </StageWrapper>
  )
}

export default Stage
