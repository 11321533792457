import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

import { Container as StyledContainer } from "../components/Grid"
import CTAWithImage from "../components/CTAWithImage"
import Snippet from "../components/Snippet"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 50px;
  padding-top: 50px;

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`

const CTASignupWithDeviceImage = ({ className }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      deviceImage: allContentfulAsset(
        filter: { contentful_id: { eq: "6vCVxh30p1iLONOpZrR5d2" } }
      ) {
        nodes {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 390)
            }
          }
        }
      }
    }
  `)

  const image = data.deviceImage.nodes.filter(
    (item) => item.node_locale === intl.locale
  )[0]

  return (
    <Container className={className}>
      <CTAWithImage
        header={Snippet({
          id: "5KwsKOiSbALAFSAmDr1UKB",
          field: "header",
        })}
        buttonText={Snippet({
          id: "5KwsKOiSbALAFSAmDr1UKB",
          field: "linkText",
        })}
        buttonUrl={Snippet({
          id: "5KwsKOiSbALAFSAmDr1UKB",
          field: "linkUrl",
        })}
        image={image}
        source="contentful"
      />
    </Container>
  )
}

export default CTASignupWithDeviceImage
