import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Box from "../Box"
import { Container as StyledContainer, Row, Col } from "../Grid"
import StyledHeading from "../Heading"
import TextStyles from "../TextStyles"
import Snippet from "../Snippet"

import { media } from "../../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 10px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 30px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 60px;
  }
`

const Header = styled.div`
  margin-bottom: 30px;
  text-align: center;

  @media ${media.tablet} {
    margin-bottom: 35px;
  }

  @media ${media.tabletLandscape} {
    margin-bottom: 50px;
  }

  @media ${media.tabletLandscape} {
    margin-bottom: 60px;
  }
`

const BenefitsWrapper = styled.div`
  /* padding-bottom: 40px;
  padding-top: 40px; */
`

const Benefit = styled(Box)`
  padding: 35px 25px;
  margin: 0 0 30px;
  min-height: calc(100% - 30px);

  @media ${media.tabletLandscape} {
    padding: 45px 35px;
  }
`

const BenefitHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
  min-height: 56px;

  @media ${media.xl} {
    margin-bottom: 25px;
    min-height: 0;
  }
`

const Heading = styled(StyledHeading)`
  margin: 0;
`

const securityTipsHeader = "2o5gNRGwLzmMzN6N1okMc8"
const securityTipsText1 = "6AkZ7CalKY5ox6RjKaFpxG"
const securityTipsText2 = "4rlsfXQHsox7GyV496s4vl"
const securityTipsText3 = "gC3DMyzKMNu6WMP5fKalG"
const securityTipsText4 = "6xOOfKNWR2hu6DQLl62suR"
const securityTipsText5 = "50YMfEHRLdrXZmGpyyUQGh"
const securityTipsText6 = "1mkLWOi0USJoJWU2JaVETE"

const Benefits = () => {
  return (
    <Container>
      <Row>
        <Col md={10} mdOffset={1} xl={8} xlOffset={2}>
          <Header>
            <Heading type="h3">
              <Snippet id={securityTipsHeader} field="header" />
            </Heading>
          </Header>
        </Col>
      </Row>

      <BenefitsWrapper>
        <TextStyles textColor="light" textSize="small">
          <Row>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText1} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText1} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText2} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText2} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText3} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText3} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText4} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText4} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText5} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText5} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={4}>
              <Benefit>
                <BenefitHeader>
                  <Heading type="h4">
                    <Snippet id={securityTipsText6} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={securityTipsText6} field="text" renderHtml />
              </Benefit>
            </Col>
          </Row>
        </TextStyles>
      </BenefitsWrapper>
    </Container>
  )
}

Benefits.propTypes = {
  showPromo: PropTypes.bool,
}

export default Benefits
