import React from "react"
import styled, { css } from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import ConditionalWrapper from "../components/ConditionalWrapper"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"
import { Container as TextBlock } from "../components/TextBlock"
import TextAndImageBlock from "../components/TextAndImageBlock"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 20px;
  padding-top: 20px;

  @media ${media.tablet} {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  ${(props) =>
    props.padding === "large" &&
    css`
      padding-bottom: 40px;
      padding-top: 40px;

      @media ${media.tablet} {
        padding-bottom: 50px;
        padding-top: 50px;
      }

      @media ${media.tabletLandscape} {
        padding-bottom: 80px;
        padding-top: 80px;
      }
    `}

  ${TextBlock} &,
  ${TextAndImageBlock} & {
    padding: 0;

    p:last-child {
      margin-bottom: 1.45em; // because we have p:last-child { margin-bottom: 0; } globally
    }
  }
`

const Text = styled.div`
  ${(props) =>
    props.limitWidth &&
    css`
      max-width: 700px;
      margin: 0 auto;
    `}

  img {
    height: auto;
    max-width: 100%;
  }

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`

const MarkdownBlock = (props) => {
  let id
  if (props.anchorId) {
    id = {
      id: props.anchorId,
    }
  }

  const columns = {
    lg: 10,
    xl: 8,
  }

  if (props.alignment === "center") {
    columns.lgOffset = 1
    columns.xlOffset = 2
  }

  return (
    <div
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
      {...id}
    >
      <TextStyles
        alignment={props.alignment}
        textColor={props.textColor}
        textSize={props.textSize}
      >
        <Container padding={props.padding}>
          <ConditionalWrapper
            condition={!props.fullWidth}
            wrapper={(children) => (
              <Row>
                <Col {...columns}>{children}</Col>
              </Row>
            )}
          >
            <ConditionalWrapper
              condition={props.alignment === "center" && props.fullWidth}
              wrapper={(children) => (
                <Row>
                  <Col {...columns}>{children}</Col>
                </Row>
              )}
            >
              {props.header && (
                <Heading type={props.headerLevel} className={props.headerColor}>
                  {props.header}
                </Heading>
              )}
              {props.subheader && (
                <Heading
                  type={props.subheaderLevel}
                  subHeader
                  className={props.subheaderColor}
                >
                  {props.subheader}
                </Heading>
              )}
            </ConditionalWrapper>
            {props.text && (
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.text.childMarkdownRemark.html,
                  }}
                />
              </Text>
            )}
          </ConditionalWrapper>
        </Container>
      </TextStyles>
    </div>
  )
}

export default MarkdownBlock
