import React from "react"
import styled from "styled-components"

import { Row, Col } from "./Grid"
import Heading from "./Heading"
import ImageContentful from "./ImageContentful"
import StyledButton from "./Button"
import ConditionalWrapper from "./ConditionalWrapper"

import { handleLinks } from "../helpers"
import { media } from "../styles"

const Wrapper = styled.div`
  background: ${({ theme }) => theme.brandPrimaryDark};
  position: relative;
  padding: 30px 20px 35px;

  @media ${media.lg} {
    padding: 75px 0 50px;
    /* height: 400px; */
  }

  @media ${media.xl} {
    /* height: 400px; */
  }
`

const Content = styled.div`
  color: ${({ theme }) => theme.brandPrimaryLight};
  margin-bottom: 30px;
  text-align: center;

  @media ${media.sm} {
    margin-bottom: 0;
    text-align: left;
  }

  h3 {
    color: ${({ theme }) => theme.brandPrimaryLight};
    margin-bottom: 5px;

    @media ${media.sm} {
      margin-bottom: 20px;
    }

    @media ${media.md} {
      margin-bottom: 25px;
    }

    @media ${media.lg} {
      margin-bottom: 30px;
    }

    @media ${media.xl} {
      margin-bottom: 35px;
    }
  }

  p,
  ul,
  ol {
    color: ${({ theme }) => theme.brandSecondaryDark};
    line-height: 1.5;
    letter-spacing: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ImageEl = styled.div`
  /* padding: 0 30px; */
  margin: 0 auto;
  max-width: 300px;

  @media ${media.sm} {
    max-width: 100%;
    padding: 0;
  }
`

const Button = styled(StyledButton)`
  margin: 15px auto 0;
  max-width: 280px;
  width: 100%;

  @media ${media.sm} {
    margin: 0;
    min-width: 280px;
    max-width: 360px;
  }

  &:hover {
    color: ${({ theme }) => theme.brandPrimaryLight};
    border-color: ${({ theme }) => theme.brandLinksHover};
    background-color: ${({ theme }) => theme.brandLinksHover};
  }

  @media ${media.md} {
    /* margin-top: 30px; */
  }

  @media ${media.lg} {
    margin-top: 0px;
  }

  @media ${media.xl} {
    margin-top: 15px;
  }
`

const CTAWithImage = (props) => {
  return (
    <Wrapper>
      <Row>
        <Col sm={6} lg={5} xl={5} lgOffset={1}>
          <Content>
            {props.header && <Heading type="h3">{props.header}</Heading>}
            {props.buttonText && props.buttonUrl && (
              <Button href={props.buttonUrl} kind="secondary" size="large">
                {props.buttonText}
              </Button>
            )}
          </Content>
        </Col>
        {props.image && (
          <Col sm={6} lg={5} xl={4} xlOffset={1} aria-hidden="true">
            <ImageEl>
              <ConditionalWrapper
                condition={props.buttonUrl}
                wrapper={(children) =>
                  handleLinks({ href: props.buttonUrl, children })
                }
              >
                <ImageContentful
                  alt={props.image.description || props.header}
                  image={props.image}
                />
              </ConditionalWrapper>
            </ImageEl>
          </Col>
        )}
      </Row>
    </Wrapper>
  )
}

export default CTAWithImage
