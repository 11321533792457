import styled from "styled-components"

const ResponsiveWrapper = styled.div`
  position: relative;
  padding-top: ${(props) => (props.padding ? props.padding : "56.2%")};

  > * {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`
export default ResponsiveWrapper
