import React from "react"
import propTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { removeHtml } from "../helpers"

const ImageWrapper = styled.span`
  display: inline-block;
  height: auto;
  max-width: ${({ width }) => `${width / 2}px`}; // half width for @2x
`

// Render inline SVG with fallback non-svg images
export default function ImageContentful({
  image,
  alt,
  inline,
  className,
  ...rest
}) {
  // If there is no image, return nothing
  if (!image && process.env.NODE_ENV === "development") {
    return (
      <div
        style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
      >
        NO IMAGE PROVIDED
      </div>
    )
  }

  // If file.contentType is missing, it's likely that the
  // query is missing the ...ContentfulImageData fragment
  if (!image.file?.contentType) {
    console.error(
      `ImageContentful: Query incomplete. Make sure you are including the ...ContentfulImageData fragment in your image query.`
    )

    if (process.env.NODE_ENV === "development") {
      return (
        <div
          style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
        >
          QUERY INCOMPLETE
        </div>
      )
    } else {
      return ""
    }
  }

  // Check for SVG image first

  if (image.file.contentType === "image/svg+xml") {
    // If "inline" flag is set, output an inlined image
    if (image.svg?.content && inline) {
      return (
        <span
          className={`wrap--svg${className ? ` ${className}` : ""}`}
          dangerouslySetInnerHTML={{ __html: image.svg.content }}
          title={removeHtml(alt || image.description)}
          {...rest}
        />
      )
    }

    // If no "inline" flag is set, or there is no SVG content in the schema, output an <img />
    return (
      <img
        alt={removeHtml(alt || image.description)}
        className={`image--svg${className ? ` ${className}` : ""}`}
        src={image.localFile.publicURL}
        height={image.file.details.image.height}
        width={image.file.details.image.width}
        {...rest}
      />
    )
  }

  // Proceed with gatsby-plugin-image for bitmaps

  // If a local file is available, use it
  if (image.localFile?.childImageSharp) {
    return (
      <GatsbyImage
        alt={removeHtml(alt || image.description)}
        className={className}
        image={image.localFile.childImageSharp.gatsbyImageData}
        {...rest}
      />
    )
  }

  // If there is no local file, fall back to the Contentful CDN
  if (image.gatsbyImageData) {
    return (
      <ImageWrapper width={image.file.details.image.width}>
        <GatsbyImage
          alt={removeHtml(alt || image.description)}
          className={className}
          image={image.gatsbyImageData}
          {...rest}
        />
      </ImageWrapper>
    )
  }

  // If this is running, either your query is incomplete or the Contentful CDN is having issues
  if (process.env.NODE_ENV === "development") {
    return (
      <div
        style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
      >
        SOMETHING BROKE
      </div>
    )
  }
}

ImageContentful.propTypes = {
  alt: propTypes.string.isRequired,
  className: propTypes.string,
  image: propTypes.object.isRequired,
  inline: propTypes.bool,
}
