import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import useMobileDetect from "use-mobile-detect-hook"

import { Container as StyledContainer, Row } from "../components/Grid"
import Markdown from "../components/Markdown"
import Heading from "../components/Heading"
import ImageContentful from "../components/ImageContentful"
import StyledButton from "../components/Button"

import { media } from "../styles"

import AppStoreSVG from "../images/badges/appstore.inline.svg"
import GooglePlaySVG from "../images/badges/googleplay.inline.svg"

const Container = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  @media ${media.sm} {
    justify-content: center;
  }
`

const ContentCol = styled.div`
  z-index: 1;
  padding: 0 ${({ theme }) => theme.styledBootstrapGrid.col.padding}px;

  @media ${media.sm} {
    max-width: ${(8 / 12) * 100}%;
  }

  @media ${media.md} {
    max-width: ${(7 / 12) * 100}%;
  }

  @media ${media.lg} {
    max-width: ${(8 / 12) * 100}%;
  }
`

const Content = styled.div`
  color: ${({ theme }) => theme.textColor};
  padding: 15px 10px;
  z-index: 1;

  @media ${media.sm} {
    padding: 30px 20px 30px 0;
  }

  @media ${media.lg} {
    padding: 20px 40px 30px 0;
  }

  @media ${media.xl} {
    padding: 20px 80px 30px 0;
  }

  p,
  ul,
  ol {
    letter-spacing: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  li {
    line-height: 1.5;
    font-size: 16px;

    @media ${media.phoneMedium} {
      font-size: 18px;
    }

    @media ${media.xl} {
      font-size: 20px;
    }
  }
`

const HeroImage = styled.div`
  margin: 20px 0 15px;

  @media ${media.sm} {
    height: 100%;
    left: calc(50% + 138px);
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;

    .gatsby-image-wrapper {
      height: 100%;
      position: static !important; /* Safari hack for .gatsby-image-wrapper */
    }
  }

  @media ${media.md} {
    left: calc(50% + 120px);
  }

  @media ${media.lg} {
    left: calc(50% + 225px);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    img {
      width: auto !important; /* IE 11 */
    }
  }
`

const Header = styled(Heading).attrs((props) => ({ type: "h1" }))`
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.brandPrimaryLight : theme.brandPrimaryDark};
  font-size: 27px;
  line-height: 34px;
  margin-bottom: 30px;

  a {
    &,
    &:hover {
      color: inherit;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    font-weight: ${({ theme }) => theme.fontWeightNormal};
  }

  @media ${media.phoneMedium} {
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 30px;
  }

  @media ${media.desktop} {
    font-size: 42px;
    line-height: 53px;
  }

  @media ${media.xl} {
    margin-bottom: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;

  @media ${media.tabletLandscape} {
    height: 410px;
  }

  @media ${media.desktop} {
    height: 490px;
  }

  ${(props) =>
    (props.imageFit === "contain" || props.imageFit === "fill") &&
    css`
      ${ContentCol} {
        max-width: 100%;

        @media ${media.sm} {
          width: 70%;
        }

        @media ${media.tabletLandscape} {
          width: 62%;
        }

        @media ${media.desktop} {
          width: 62%;
        }
      }

      ${HeroImage} {
        max-width: 780px;

        @media ${media.sm} {
          left: calc(50% + 130px);

          img {
            object-fit: contain !important;
            object-position: center left !important;
          }
        }

        @media ${media.md} {
          left: calc(50% + 130px);
        }

        @media ${media.lg} {
          left: calc(50% + 95px);
        }

        @media ${media.xl} {
          height: 110%;
          left: calc(50% + 155px);

          img {
            object-fit: contain !important;
            object-position: center left !important;
          }
        }
      }
    `}

  ${(props) =>
    props.imageFit === "fill" &&
    css`
      ${HeroImage} {
        @media ${media.sm} {
          img {
            object-fit: cover !important;
            object-position: center left !important;
          }
        }
      }
    `}

  ${(props) =>
    props.imageFit === "contain" &&
    props.imagePosition === "tight" &&
    css`
      ${ContentCol} {
        max-width: 100%;

        @media ${media.sm} {
          width: 65%;
        }

        @media ${media.tabletLandscape} {
          width: 57%;
        }

        @media ${media.desktop} {
          width: 59%;
        }
      }

      ${HeroImage} {
        @media ${media.md} {
          left: calc(50% + 75px);
        }

        @media ${media.lg} {
          left: calc(50% + 35px);
        }

        @media ${media.xl} {
          top: 30px;
          left: calc(50% + 65px);
        }
      }
    `}

  ${(props) =>
    props.imageFit === "cover" &&
    css`
      ${ContentCol} {
        @media ${media.sm} {
          max-width: ${(5 / 8) * 100}%;
        }

        @media ${media.md} {
          max-width: 50%;
        }

        @media ${media.xl} {
          max-width: ${(5 / 12) * 100}%;
        }
      }

      ${HeroImage} {
        margin: 0;

        @media ${media.sm} {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;

          &:after {
            content: "";
            display: block;
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            opacity: 0.3;
          }
        }
      }

      h1,
      p {
        @media ${media.sm} {
          color: ${({ theme }) => theme.brandPrimaryLight};
        }
      }
    `}
`

const ButtonRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 30px 0 0;

  @media ${media.sm} {
    align-items: flex-start;
  }
`

const Button = styled(StyledButton)`
  margin-top: 0;

  .has--background-purple & {
    background-color: ${({ theme }) => theme.brandPrimaryMedium};
    border-color: ${({ theme }) => theme.brandPrimaryMedium};
    color: ${({ theme }) => theme.brandPrimaryDark};

    :hover {
      background: ${({ theme }) => theme.brandLinksHover};
      border-color: ${({ theme }) => theme.brandLinksHover};
      color: ${({ theme }) => theme.brandPrimaryLight};
    }
  }

  @media ${media.sm} {
    margin-right: 22px;
  }
`

const BadgeRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;
  min-height: 68px;
  width: 100%;

  @media ${media.sm} {
    align-items: flex-start;
    flex-direction: row;
    margin: 10px -10px 0;
    min-height: 58px;
    width: 380px;
  }

  @media ${media.md} {
    margin-top: 10px;
  }
`

const AppStoreBadge = styled.div`
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.brandPrimaryLight : theme.brandPrimaryDark};
  display: none;
  margin: 13px 10px 0;
  width: 165px;

  @media ${media.sm} {
    margin-top: 0;
    width: calc(50% - 20px);
  }

  a,
  a:hover {
    color: inherit;
  }

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
`

const GooglePlayBadge = styled.div`
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.brandPrimaryLight : theme.brandPrimaryDark};
  display: none;
  margin: 13px 10px 0;
  width: 165px;

  @media ${media.sm} {
    margin-top: 0;
    width: calc(50% - 20px);
  }

  a,
  a:hover {
    color: inherit;
  }

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
`

const FinePrint = styled.div`
  margin-top: 15px;

  p {
    font-size: inherit;
    margin: 0;
  }
`

const renderImage = (props) => {
  return (
    <HeroImage aria-hidden="true">
      <ImageContentful
        alt={props.image.description || props.header}
        image={props.image}
      />
    </HeroImage>
  )
}

const Hero = (props) => {
  const detectMobile = useMobileDetect()
  const [isIos, setIsIos] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  // Workaround with useEffect and useState because it just doesn't want to work otherwise
  useEffect(() => {
    setIsIos(detectMobile.isIos())
    setIsAndroid(detectMobile.isAndroid())
    setIsDesktop(detectMobile.isDesktop())
  }, [])

  return (
    <Wrapper
      imageFit={props.imageFit}
      imagePosition={props.imagePosition}
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
    >
      {props.image && props.imageFit === "cover" && renderImage(props)}
      <Container>
        <Row>
          <ContentCol sm={8} md={7} lg={7} xl={8}>
            <Content>
              {props.header && (
                <Header dangerouslySetInnerHTML={{ __html: props.header }} />
              )}
              {props.text && (
                <div>
                  <Markdown>{props.text.text}</Markdown>
                </div>
              )}
              {((props.buttonText && props.buttonUrl) ||
                props.includeAppStoreBadges) && (
                <ButtonRow>
                  {props.buttonText && props.buttonUrl && (
                    <Button href={props.buttonUrl} kind="primary" size="large">
                      {props.buttonText}
                    </Button>
                  )}

                  {props.includeAppStoreBadges && (
                    <BadgeRow>
                      <AppStoreBadge
                        style={{
                          display: isDesktop || isIos ? "block" : "none",
                        }}
                      >
                        <a
                          href="https://apps.apple.com/de/app/b%C3%B6rse-stuttgart-digital-exch/id1545552589"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <AppStoreSVG />
                        </a>
                      </AppStoreBadge>
                      <GooglePlayBadge
                        style={{
                          display: isDesktop || isAndroid ? "block" : "none",
                        }}
                      >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.bsdex.android"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <GooglePlaySVG />
                        </a>
                      </GooglePlayBadge>
                    </BadgeRow>
                  )}
                </ButtonRow>
              )}
              {props.finePrint && (
                <FinePrint>
                  <small>
                    <Markdown>{props.finePrint.finePrint}</Markdown>
                  </small>
                </FinePrint>
              )}
            </Content>
          </ContentCol>
        </Row>
        {props.image && props.imageFit !== "cover" && renderImage(props)}
      </Container>
    </Wrapper>
  )
}

export default Hero
