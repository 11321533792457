import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import Heading from "../components/Heading"

import { getHighlightedText } from "../helpers"

import { media, mixins } from "../styles"
import arrow from "../images/icons/arrow-down.svg"
import ArrowDown from "../images/icons/arrow-down.inline.svg"

const Question = styled(Heading).attrs((props) => ({ type: "h3" }))`
  ${mixins.title5}
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.brandPrimaryMedium : theme.brandPrimaryDark};
  cursor: pointer;
  margin: 0;
  padding: 20px 0;

  a {
    display: flex;
  }

  @media ${media.tabletLandscape} {
    padding: 25px 0;
  }
`

const Answer = styled.div`
  display: none;
  padding-bottom: 25px;

  P,
  li {
    ${mixins.copySmall};
  }
`

const Arrow = styled(ArrowDown)`
  color: ${({ theme }) => theme.textColor};
  height: 13px;
  margin: 7px 0 0 auto;
  transition: transform 250ms;
  width: 19px;
`

const Item = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.borderColor};
  scroll-padding-top: 100px;

  ${(props) =>
    props.isOpen &&
    css`
      ${Answer} {
        display: block;
      }

      ${Arrow} {
        transform: rotate(180deg);
      }
    `}
`

const FaqItem = ({
  isCollapsible,
  isOpen: outerIsOpen,
  faq,
  highlightText,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (allowed) => {
    if (allowed) {
      setIsOpen(!isOpen)
    }
  }

  useEffect(() => {
    setIsOpen(outerIsOpen)
  }, [outerIsOpen])

  return (
    <Item isOpen={isOpen} id={id}>
      <Question onClick={() => handleClick(isCollapsible)}>
        <a href={`#${id}`} onClick={(e) => e.preventDefault()}>
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(faq.question, highlightText),
            }}
          />
          {isCollapsible ? (
            <Arrow src={arrow} alt="" aria-hidden="true" />
          ) : null}
        </a>
      </Question>
      <Answer
        dangerouslySetInnerHTML={{
          __html: getHighlightedText(
            faq.answer.childMarkdownRemark.html,
            highlightText
          ),
        }}
      ></Answer>
    </Item>
  )
}

export default FaqItem
