import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../Box"
import { Container as StyledContainer, Row, Col } from "../Grid"
import StyledHeading from "../Heading"
import TextStyles from "../TextStyles"
import Button from "../Button"
import Snippet from "../Snippet"
import ImageContentful from "../ImageContentful"

import { media } from "../../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 10px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 30px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 60px;
  }
`

const Header = styled.div`
  margin-bottom: 30px;
  text-align: center;

  @media ${media.tablet} {
    margin-bottom: 35px;
  }

  @media ${media.tabletLandscape} {
    margin-bottom: 50px;
  }

  @media ${media.tabletLandscape} {
    margin-bottom: 60px;
  }
`

const BenefitsWrapper = styled.div`
  /* padding-bottom: 40px;
  padding-top: 40px; */
`

const Benefit = styled(Box)`
  padding: 35px 25px;
  margin: 0 0 30px;
  min-height: calc(100% - 30px);

  @media ${media.tabletLandscape} {
    padding: 45px 35px;
  }
`

const BenefitHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
  min-height: 56px;

  @media ${media.xl} {
    margin-bottom: 25px;
    min-height: 0;
  }
`

const Icon = styled.div`
  align-items: center;
  display: flex;
  flex: none;
  height: 30px;
  margin-right: 10px;
  width: 32px;

  .wrap--svg {
    display: block;
    width: 27px;

    svg {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }
`

const Heading = styled(StyledHeading)`
  margin: 0;
`

const SpecialOffer = styled.div`
  background: ${({ theme }) => theme.brandSecondaryLight};
  /* display: flex; */
  flex-flow: column;
  margin: 25px 0 0;
  padding: 25px 25px;

  @media ${media.sm} {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.brandSecondaryDark};

    strong {
      color: ${({ theme }) => theme.brandPrimaryDark};
    }
  }

  .button {
    margin-left: auto;
  }
`

const homeBenefitsHeader = "16zM9YUgZOWIZiXCmKmh7v"
const homeBenefitsText1 = "24f4xm3apDA0GH4S8neUeb"
const homeBenefitsText2 = "45bMcB1aD7fLgb559oJDV5"
const homeBenefitsText3 = "1foOkWjIqvpSwSowgX3VWO"
const homeBenefitsText4 = "56rCW9gA5UpaR0RMb25ZOv"
const homeBenefitsSpecialOffer = "a5BVPtoyBoxmQd763xxuk"

const Benefits = ({ showPromo, showEmailPromo }) => {
  const data = useStaticQuery(graphql`
    query {
      iconLowFees: contentfulAsset(
        contentful_id: { eq: "1DDOhtRX9ONIUHpIk4qXu8" }
      ) {
        ...ContentfulImageData
      }
      iconTrust: contentfulAsset(
        contentful_id: { eq: "6SVggi9J5oNXAuubkuYuTp" }
      ) {
        ...ContentfulImageData
      }
      iconGermany: contentfulAsset(
        contentful_id: { eq: "7bCLlmIOjZPmvyRMcXna7z" }
      ) {
        ...ContentfulImageData
      }
      iconShield: contentfulAsset(
        contentful_id: { eq: "7px8Zh2Cm0bL9Hw3AbqO6T" }
      ) {
        ...ContentfulImageData
      }
    }
  `)

  const emailSubject = encodeURIComponent(
    Snippet({ id: "2JmuLVrRwFfSAyZIgFOQH8", field: "header" })
  )

  const emailBody = encodeURIComponent(
    Snippet({ id: "2JmuLVrRwFfSAyZIgFOQH8", field: "text" })
  )

  return (
    <Container>
      <Row>
        <Col md={10} mdOffset={1} xl={8} xlOffset={2}>
          <Header>
            <Heading type="h3">
              <Snippet id={homeBenefitsHeader} field="header" />
            </Heading>
          </Header>
        </Col>
      </Row>

      <BenefitsWrapper>
        <TextStyles textColor="light" textSize="small">
          <Row>
            <Col sm={showPromo ? 12 : 6}>
              <Benefit>
                <Row>
                  <Col sm={showPromo ? 6 : 12}>
                    <BenefitHeader>
                      <Icon aria-hidden="true">
                        <ImageContentful
                          alt=""
                          image={data.iconLowFees}
                          inline
                        />
                      </Icon>
                      <Heading type="h4">
                        <Snippet id={homeBenefitsText1} field="header" />
                      </Heading>
                    </BenefitHeader>
                    <Snippet id={homeBenefitsText1} field="text" renderHtml />
                  </Col>
                  {showPromo ? (
                    <Col sm={6}>
                      <SpecialOffer>
                        <Snippet
                          id={homeBenefitsSpecialOffer}
                          field="text"
                          renderHtml
                        />
                        <Button
                          kind="primary-outline"
                          href={
                            showEmailPromo
                              ? `mailto:support@bsdex.de?subject=${emailSubject}&body=${emailBody}`
                              : Snippet({
                                  id: homeBenefitsSpecialOffer,
                                  field: "linkUrl",
                                })
                          }
                        >
                          <Snippet
                            id={homeBenefitsSpecialOffer}
                            field="linkText"
                          />
                        </Button>
                      </SpecialOffer>
                    </Col>
                  ) : null}
                </Row>
              </Benefit>
            </Col>
            <Col sm={showPromo ? 4 : 6}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true">
                    <ImageContentful alt="" image={data.iconTrust} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={homeBenefitsText2} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={homeBenefitsText2} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={showPromo ? 4 : 6}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true">
                    <ImageContentful alt="" image={data.iconGermany} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={homeBenefitsText3} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={homeBenefitsText3} field="text" renderHtml />
              </Benefit>
            </Col>
            <Col sm={showPromo ? 4 : 6}>
              <Benefit>
                <BenefitHeader>
                  <Icon aria-hidden="true">
                    <ImageContentful alt="" image={data.iconShield} inline />
                  </Icon>
                  <Heading type="h4">
                    <Snippet id={homeBenefitsText4} field="header" />
                  </Heading>
                </BenefitHeader>
                <Snippet id={homeBenefitsText4} field="text" renderHtml />
              </Benefit>
            </Col>
          </Row>
        </TextStyles>
      </BenefitsWrapper>
    </Container>
  )
}

Benefits.propTypes = {
  showPromo: PropTypes.bool,
  showEmailPromo: PropTypes.bool,
}

export default Benefits
