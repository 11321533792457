import React from "react"

import Snippet from "../components/Snippet"
import TextBlock from "../components/TextBlock"

const SupportForm = () => {
  return (
    <TextBlock
      alignment="center"
      limitWidth={true}
      headerLevel="h2"
      header={Snippet({
        id: "vBCzcPH5Wvlb9WnRruP57",
        field: "header",
      })}
      text={Snippet({
        id: "vBCzcPH5Wvlb9WnRruP57",
        field: "text",
      })}
      linkText={Snippet({
        id: "vBCzcPH5Wvlb9WnRruP57",
        field: "linkText",
      })}
      linkUrl={Snippet({
        id: "vBCzcPH5Wvlb9WnRruP57",
        field: "linkUrl",
      })}
    />
  )
}

export default SupportForm
