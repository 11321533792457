import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Container as StyledContainer } from "../components/Grid"
import ImageContentful from "../components/ImageContentful"
import Snippet from "../components/Snippet"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding: ${({ theme }) => `${theme.spacingXs * 2}px 0 ${theme.spacingXs}px`};

  @media ${media.sm} {
    padding: ${({ theme }) =>
      `${theme.spacing * 2}px 0 ${theme.spacing * 1.5}px`};
  }
`

const Header = styled.div`
  color: ${({ theme }) => theme.brandSecondaryDark};
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
  opacity: 0.7;
`

const Logos = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacingXs}px;

  @media ${media.sm} {
    padding: 0 ${({ theme }) => theme.spacing}px;
  }

  @media ${media.md} {
    flex-flow: row nowrap;
    padding: 0;
  }
`

const Logo = styled.li`
  align-items: center;
  display: flex;
  height: 50px;
  opacity: 0.5;
  margin: 0 0 ${({ theme }) => theme.spacingXs}px;
  width: calc(50% - 15px);

  @media ${media.sm} {
    margin: 0 0 ${({ theme }) => theme.spacing}px;
    width: calc(33.3333333% - 35px);
  }

  @media ${media.md} {
    width: calc(16.666666% - 35px);
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  img {
    filter: grayscale(100%);
    max-height: 100%;
  }
`

export const PressLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      cointelegraph: contentfulAsset(
        contentful_id: { eq: "1wcvExUFLJnyigi9EzXZZ3" }
      ) {
        ...ContentfulImageData
      }
      bild: contentfulAsset(contentful_id: { eq: "1TGv5mDgr5GzVUnbYqyM6K" }) {
        ...ContentfulImageData
      }
      finanzenNet: contentfulAsset(
        contentful_id: { eq: "926y2m6iti6VM5i7f47hl" }
      ) {
        ...ContentfulImageData
      }
      cryptomonday: contentfulAsset(
        contentful_id: { eq: "6QwZQVSOe6y8m3XxnRGdrj" }
      ) {
        ...ContentfulImageData
        localFile {
          childImageSharp {
            gatsbyImageData(height: 92, width: 200, layout: CONSTRAINED)
          }
        }
      }
      welt: contentfulAsset(contentful_id: { eq: "6T1boBGc6ANkdrTXBf5KVX" }) {
        ...ContentfulImageData
      }
      t3n: contentfulAsset(contentful_id: { eq: "7oRCiabghR5ONkhRbiPjIg" }) {
        ...ContentfulImageData
      }
    }
  `)

  return (
    <Container>
      <Header>
        <Snippet id="tAKoHsN5E0zRizHZhziJQ" field="header" />
      </Header>
      <Logos>
        <Logo>
          <ImageContentful image={data.welt} alt="Die Welt" />
        </Logo>
        <Logo>
          <ImageContentful image={data.bild} alt="BILD" />
        </Logo>
        <Logo>
          <ImageContentful image={data.finanzenNet} alt="Finanzen.net" />
        </Logo>
        <Logo>
          <ImageContentful
            image={data.cryptomonday}
            alt="Crypto Monday"
            style={{ maxWidth: 100 }}
          />
        </Logo>
        <Logo>
          <ImageContentful image={data.t3n} alt="T3N" />
        </Logo>
        <Logo>
          <ImageContentful image={data.cointelegraph} alt="Cointelegraph" />
        </Logo>
      </Logos>
    </Container>
  )
}

export default PressLogos
