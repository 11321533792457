import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import Heading from "./Heading"
import ImageContentful from "./ImageContentful"
import Snippet from "./Snippet"

import { media } from "../styles"

const Wrapper = styled.div`
  background: ${({ theme }) => theme.brandSecondaryLight};
  padding: 40px 0 80px;
  margin: 0 0 90px;
`

const Header = styled.div`
  margin: 0 0 45px;
  text-align: center;
`

const Container = styled(StyledContainer)`
  h4 {
    color: ${({ theme }) => theme.brandPrimaryDark};
    margin-bottom: ${({ theme }) => theme.spacingXs}px;

    @media ${media.sm} {
      margin-bottom: ${({ theme }) => theme.spacing}px;
    }
  }
`

const Download = styled.a`
  color: ${({ theme }) => theme.brandSecondaryDark};
  transition: color ${({ theme }) => theme.transitionTime};

  svg {
    color: ${({ theme }) => theme.brandLinks};
    margin-right: 10px;
    transition: color ${({ theme }) => theme.transitionTime};
  }

  &:hover svg {
    color: ${({ theme }) => theme.brandLinksHover};
  }
`

const Thumbnail = styled(ImageContentful)`
  margin: 0 0 15px;
`

const Name = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
`

const PressDownloads = () => {
  const intl = useIntl()

  const { allContentfulMediaDownload } = useStaticQuery(
    graphql`
      {
        allContentfulMediaDownload(sort: { fields: sortOrder, order: ASC }) {
          nodes {
            name
            node_locale
            file {
              file {
                fileName
              }
            }
            thumbnail {
              ...ContentfulImageData
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 190
                    width: 285
                    quality: 80
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  const downloads = allContentfulMediaDownload.nodes.filter(
    (download) => download.node_locale === intl.locale
  )

  return (
    <Wrapper>
      <Container>
        <Header>
          <Heading type="h2" className="h3">
            <Snippet id="4TpUIfyozruwbntlABuRwk" field="header" />
          </Heading>
          <p>
            <Snippet id="4TpUIfyozruwbntlABuRwk" field="text" />
          </p>
        </Header>

        <Row justifyContent="center">
          {downloads.map((download) => (
            <Col sm={4} xl={3} key={download.name}>
              <Download
                href={`/downloads/${download.file.file.fileName}`}
                download
              >
                <Thumbnail image={download.thumbnail} alt={download.name} />
                <Name>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      transform="translate(1)"
                    >
                      <path d="M18 12v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4" />
                      <polyline points="4 7 9 12 14 7" />
                      <line x1="9" x2="9" y1="12" />
                    </g>
                  </svg>
                  <strong>{download.name}</strong>
                </Name>
              </Download>
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

export default PressDownloads
