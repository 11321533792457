import React from "react"
import styled, { css } from "styled-components"

import { media, mixins } from "../styles"

const Wrapper = styled.div`
  ${(props) =>
    props.textColor === "light" &&
    css`
      ${mixins.textLight}
    `}

  ${(props) =>
    props.alignment === "center" &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.alignment === "center on mobile only" &&
    css`
      @media ${media.mdDown} {
        text-align: center;
      }
    `}
`

const TextStyles = ({ textColor, textSize, alignment, children }) => {
  return (
    <Wrapper
      alignment={alignment}
      textColor={textColor}
      className={`${textSize === "small" ? "text-small" : ""}`}
    >
      {children}
    </Wrapper>
  )
}

export default TextStyles
