import React from "react"
import styled from "styled-components"

import StyledBox from "../components/Box"
import { Col, Container, Row } from "../components/Grid"
import Heading from "../components/Heading"
import Link from "../components/Link"
import Snippet from "../components/Snippet"

import { media, mixins } from "../styles"

const Wrapper = styled.div``

const PressLinkList = styled.div`
  margin-top: 40px;
`

const Box = styled(StyledBox)`
  border-radius: 7px;
  display: flex;
  flex-flow: column;
  min-height: 100%;
  padding: 35px 25px;

  @media ${media.lg} {
    padding: 45px 35px;
  }
`

const PressLink = styled(Link)`
  ${mixins.textLight}
  color: inherit;
  display: block;
  height: calc(100% - 20px);
  margin: 0 0 20px;

  @media ${media.sm} {
    margin: 0 0 30px;
    height: calc(100% - 30px);
  }

  @media ${media.lg} {
    height: 275px;
  }

  h3 {
    transition: color ${({ theme }) => theme.transitionTime};
  }

  p {
    ${mixins.copySmall}
    margin-bottom: 20px;
  }

  &:hover {
    color: inherit;

    ${Box} {
      box-shadow: ${({ theme }) =>
        theme.name === "dark"
          ? `inset 0 0 0 1px ${theme.borderColor}`
          : theme.boxShadowMediumHover} !important;
    }

    h3 {
      color: ${({ theme }) => theme.brandPrimaryDark};
    }
  }
`

const ReadMore = styled.span`
  ${mixins.copySmall};
  align-items: center;
  color: ${({ theme }) => theme.brandPrimaryDark};
  display: flex;
  margin: auto 0 0 auto;

  svg {
    margin-left: 5px;

    @media ${media.lg} {
      margin-left: 10px;
    }
  }
`

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="12"
    viewBox="0 0 11 12"
  >
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="M4.3993 1.235l4.8484 4.8273-4.8484 4.8274" />
      <path strokeLinecap="square" d="M1 6.0623h7" />
    </g>
  </svg>
)

const pressLinksNews = "26oRqHROIKejovqUbUP48P"
const pressLinksPressReleases = "07cRErXZsYUqNtXFWno5A"
const pressLinksContact = "4G4VtG3EOZ2QZgSldc0qpg"

const PressLinks = () => {
  return (
    <Wrapper>
      <Container>
        <PressLinkList>
          <Row>
            <Col sm="6" lg="6">
              <PressLink
                to={Snippet({
                  id: pressLinksPressReleases,
                  field: "linkUrl",
                })}
              >
                <Box>
                  <Heading type="h3" className="h4">
                    <Snippet id={pressLinksPressReleases} field="header" />
                  </Heading>
                  <p>
                    <Snippet id={pressLinksPressReleases} field="text" />
                  </p>
                  <ReadMore>
                    <span>
                      <Snippet id={pressLinksPressReleases} field="linkText" />
                    </span>
                    <Arrow />
                  </ReadMore>
                </Box>
              </PressLink>
            </Col>
            <Col sm="6" lg="6">
              <PressLink
                to={Snippet({
                  id: pressLinksContact,
                  field: "linkUrl",
                })}
              >
                <Box>
                  <Heading type="h3" className="h4">
                    <Snippet id={pressLinksContact} field="header" />
                  </Heading>
                  <p>
                    <Snippet id={pressLinksContact} field="text" />
                  </p>
                  <ReadMore>
                    <span>
                      <Snippet id={pressLinksContact} field="linkText" />
                    </span>
                    <Arrow />
                  </ReadMore>
                </Box>
              </PressLink>
            </Col>
          </Row>
        </PressLinkList>
      </Container>
    </Wrapper>
  )
}

export default PressLinks
