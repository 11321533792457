import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import chunk from "lodash-es/chunk"

import { Container, Row, Col } from "../components/Grid"
import StyledPagination, { PrevLink, NextLink } from "../components/Pagination"
import Snippet from "../components/Snippet"
import { media } from "../styles"

const Pagination = styled(StyledPagination)`
  @media ${media.lg} {
    justify-content: flex-start;
  }
`

const AnnouncementList = () => {
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      allContentfulAnnouncement(sort: { fields: date, order: DESC }) {
        edges {
          node {
            contentful_id
            title
            node_locale
            file {
              file {
                fileName
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          documentDir
        }
      }
    }
  `)

  const announcements = data.allContentfulAnnouncement.edges.filter(
    ({ node }) => node.node_locale === intl.locale
  )
  const announcementChunks = chunk(announcements, 10)

  return (
    <Container>
      <Row>
        <Col lg={10} xl={8}>
          {data.allContentfulAnnouncement.edges.length >= 1 && (
            <>
              <h2>
                <Snippet id="5cGmBpNp1IHf0CKLTJtVlT" field="header" />
              </h2>
              {announcementChunks.length > 1 ? (
                <p>
                  <FormattedMessage
                    id="pagination.pageOf"
                    values={{
                      currentPage: currentPage + 1,
                      totalPages: announcementChunks.length,
                    }}
                  />
                </p>
              ) : null}
              <div className="linklist">
                <ul>
                  {announcementChunks.map((chunk, i) =>
                    chunk.map(
                      ({ node }) =>
                        node.title &&
                        node.file && (
                          <li
                            key={node.contentful_id}
                            className={
                              i !== currentPage ? "sr-only" : undefined
                            }
                          >
                            <a
                              href={`${data.site.siteMetadata.documentDir}/legal/${node.file.file.fileName}`}
                              target="_blank"
                              rel="noopener"
                            >
                              {node.title}
                            </a>
                          </li>
                        )
                    )
                  )}
                </ul>
              </div>
              {announcementChunks.length > 1 ? (
                <Pagination>
                  <PrevLink
                    as="button"
                    disabled={currentPage === 0}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  />
                  {announcementChunks.map((_chunk, i) => (
                    <button
                      className={`link ${currentPage === i ? `active` : null}`}
                      onClick={() => setCurrentPage(i)}
                      key={i}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <NextLink
                    as="button"
                    disabled={currentPage === announcementChunks.length - 1}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  />
                </Pagination>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default AnnouncementList
