import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { Link as IntlLink } from "gatsby-plugin-react-intl"

import ArrowRightSVG from "../images/icons/arrow-right.inline.svg"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  activeClassName,
  arrow,
  children,
  intl,
  partiallyActive,
  rel,
  target,
  to,
  ...other
}) => {
  // We assume that any internal link (intended for Gatsby) will
  // start with exactly one slash, and that anything else is external.
  // Exception: links that start with # (for same-page navigation) and
  // email links should be kept basic (no rel or target).
  const internal = /^\/(?!\/)/.test(to)
  const basicLink = /^#|mailto|tel/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal && target !== "_blank") {
    if (intl !== undefined && intl !== true) {
      return (
        <GatsbyLink
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          <span>
            {children}
            {arrow && <ArrowRightSVG className="link-icon" />}
          </span>
        </GatsbyLink>
      )
    } else {
      return (
        <IntlLink
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          <span>
            {children}
            {arrow && <ArrowRightSVG className="link-icon" />}
          </span>
        </IntlLink>
      )
    }
  }

  return (
    <a
      href={to}
      target={basicLink ? undefined : target || "_blank"}
      rel={
        basicLink
          ? undefined
          : rel || (!target ? "noopener noreferrer" : undefined)
      }
      {...other}
    >
      <span>
        {children}
        {arrow && <ArrowRightSVG className="link-icon" />}
      </span>
    </a>
  )
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  arrow: PropTypes.bool,
  children: PropTypes.node,
  intl: PropTypes.bool,
  partiallyActive: PropTypes.bool,
  rel: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default Link
