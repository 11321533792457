import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { media } from "../styles"

const Label = styled.label`
  color: ${({ theme }) => theme.brandSecondaryDark};
  display: block;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  letter-spacing: 0.02em;
  line-height: 1;
  margin-bottom: 5px;

  @media ${media.lg} {
    margin-bottom: 10px;
  }

  a {
    color: ${({ theme }) => theme.brandPrimaryMedium};

    &:hover {
      text-decoration: underline;
    }
  }
`

const InputField = styled.input`
  appearance: none;
  background: ${({ theme }) => theme.inputBackground};
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.inputColor};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: 0.02em;
  line-height: 1;
  margin-bottom: 20px;
  padding: 15px 18px;
  width: 100%;

  &::placeholder {
    font-weight: ${({ theme }) => theme.fontWeightNormal};
  }

  @media ${media.tablet} {
    margin-bottom: 30px;
  }

  @media ${media.tableLandscape} {
    font-size: 16px;
  }
`

const Input = ({
  className,
  hideLabel,
  label,
  name,
  onChange,
  placeholder,
  prefix,
  required,
  type,
  value,
}) => {
  return (
    <div className={className}>
      <Label
        className={`${hideLabel ? "sr-only" : ""}`}
        htmlFor={`${prefix}${name}`}
      >
        {label}
        {required && "*"}
      </Label>
      <InputField
        id={`${prefix}${name}`}
        name={`${prefix}${name}`}
        onChange={onChange}
        required={required}
        placeholder={`${placeholder}${required ? "*" : ""}`}
        type={type ? type : "text"}
        value={value}
      />
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default Input
