import React, { Component } from "react"
import styled from "styled-components"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import addToMailchimp from "gatsby-plugin-mailchimp"

// import components
import { Container, Row as StyledRow, Col } from "../components/Grid"
import Heading from "../components/Heading"
import Input from "../components/Input"
import Checkbox from "../components/Checkbox"
import Snippet from "../components/Snippet"
import Button from "../components/Button"
import LoadingAnimation from "../images/icons/loading-dark.svg"

import { media } from "../styles"

const SignupContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacingXs}px;

  @media ${media.sm} {
    margin-bottom: ${({ theme }) => theme.spacing * 2}px;
    padding: 0;
  }
`

const SignupHeader = styled(Heading).attrs((props) => ({ type: "h4" }))`
  margin: 0 0 15px;

  @media ${media.sm} {
    margin-bottom: 35px;
  }
`

const Message = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;

  @media ${media.sm} {
    width: 80%;
  }

  @media ${media.md} {
    width: 70%;
  }
`

const ErrorMessage = styled.div`
  background: ${({ theme }) => theme.brandPrimaryDark};
  color: ${({ theme }) => theme.brandPrimaryLight};
  margin-bottom: 30px;
  padding: 20px;

  p {
    margin: 0;
  }
`

const Form = styled.form``

const Row = styled(StyledRow)``

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */
`

const Overlay = styled.div`
  background: ${({ theme }) => theme.brandPrimaryLightTransparent};
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`

class FormNewsletter extends Component {
  state = {
    EMAIL: "",
    FNAME: "",
    LNAME: "",
    result: "",
    message: "",
    loading: false,
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    addToMailchimp(
      this.state.EMAIL,
      {
        FNAME: this.state.FNAME,
        LNAME: this.state.LNAME,
      },
      this.props.intl.formatMessage({ id: "newsletter.form.mailchimpEndpoint" })
    )
      .then((data) => {
        this.setState({
          loading: false,
          result: data.result,
          message: data.msg,
        })
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }

  render() {
    const { intl } = this.props
    let content

    if (this.state.result === "success") {
      content = (
        <Message>
          <p>{this.state.message}</p>
        </Message>
      )
    } else {
      content = (
        <>
          {this.state.loading && (
            <Overlay>
              <img src={LoadingAnimation} alt="Loading..." />
            </Overlay>
          )}
          {this.state.result === "error" && (
            <ErrorMessage>
              <p>
                <strong>
                  <FormattedMessage id="newsletter.form.error" />:
                </strong>{" "}
                {this.state.message}
              </p>
            </ErrorMessage>
          )}

          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={6}>
                <Input
                  name="FNAME"
                  label={intl.formatMessage({
                    id: "newsletter.form.firstname",
                  })}
                  hideLabel={true}
                  type="text"
                  prefix="nl-"
                  onChange={this.handleChange}
                  placeholder={intl.formatMessage({
                    id: "newsletter.form.firstname",
                  })}
                  required
                />
              </Col>
              <Col sm={6}>
                <Input
                  name="EMAIL"
                  label={intl.formatMessage({ id: "newsletter.form.email" })}
                  hideLabel={true}
                  type="email"
                  prefix="nl-"
                  onChange={this.handleChange}
                  placeholder={intl.formatMessage({
                    id: "newsletter.form.email",
                  })}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  name="DATENSCHUTZ"
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "newsletter.form.accept",
                        }),
                      }}
                    />
                  }
                  prefix="nl-"
                  required
                />
              </Col>
            </Row>
            <ButtonRow>
              <Button as="button" type="submit" kind="primary" size="large">
                <Snippet id="lkiHMiAZzukhSPUuBgmTh" field="linkText" />
              </Button>
            </ButtonRow>
          </Form>
        </>
      )
    }
    return (
      <SignupContainer>
        <Container>
          <SignupHeader>
            <Snippet id="lkiHMiAZzukhSPUuBgmTh" field="header" />
          </SignupHeader>
          {content}
        </Container>
      </SignupContainer>
    )
  }
}

export default injectIntl(FormNewsletter)
