import React from "react"
import styled from "styled-components"

import {
  Container as StyledContainer,
  Row,
  Col as StyledCol,
} from "../components/Grid"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"
import Button from "../components/Button"

import { renderContentfulBlocks } from "../helpers/contentful"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .image--svg {
    margin-bottom: 20px;
  }
`

const Col = styled(StyledCol)`
  margin-bottom: ${({ theme }) => theme.spacingXs}px;

  @media ${media.sm} {
    margin-bottom: ${({ theme }) => theme.spacing}px;
  }
`

const Content = styled.div``

const Wrapper = styled.div`
  ${(props) =>
    props.columnStyle === "splitters" &&
    css`
      ${Content} ${Col} {
        @media ${media.sm} {
          padding: 0 35px;

          &:first-child {
            border-right: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
          }

          &:last-child {
            border-left: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
          }
        }
      }
    `}

  ${(props) =>
    props.textColor === "light" &&
    css`
      ${Col} {
        ${mixins.textLight}
      }
    `}

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`

const Header = styled.div`
  margin-bottom: 20px;

  @media ${media.sm} {
    margin-bottom: 30px;
  }

  @media ${media.md} {
    margin-bottom: 40px;
    text-align: center;
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: 20px;

  @media ${media.sm} {
    margin-top: 30px;
  }
`

const Text2Cols = (props) => {
  return (
    <Wrapper className={`has--background-${props.backgroundColor || "white"}`}>
      <Container>
        {props.header && (
          <Row>
            <Col lg={10} lgOffset={1} xl={8} xlOffset={2}>
              <Header>
                <Heading type={props.headerLevel} className={props.headerColor}>
                  {props.header}
                </Heading>
                {props.subheader && (
                  <Heading type="h4" subHeader className={props.subheaderColor}>
                    {props.subheader}
                  </Heading>
                )}
              </Header>
            </Col>
          </Row>
        )}
        <Content>
          <TextStyles
            alignment={props.alignment}
            textColor={props.textColor}
            textSize={props.textSize}
          >
            <Row>
              <Col sm={6}>
                {renderContentfulBlocks(props.text1, { ...props })}
              </Col>
              <Col sm={6}>
                {renderContentfulBlocks(props.text2, { ...props })}
              </Col>
            </Row>
          </TextStyles>
        </Content>
        {props.buttonText && props.buttonUrl && (
          <ButtonRow>
            <Button href={props.buttonUrl} kind="primary" size="large">
              {props.buttonText}
            </Button>
          </ButtonRow>
        )}
      </Container>
    </Wrapper>
  )
}

export default Text2Cols
