import React from "react"
import styled, { css } from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"

import { renderContentfulBlocks } from "../helpers/contentful"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${media.tablet} {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`

const Wrapper = styled.div`
  ${(props) =>
    props.compact &&
    css`
      ${Container} {
        padding-bottom: 35px;
        padding-top: 40px;

        @media ${media.sm} {
          padding-bottom: 80px;
          padding-top: 80px;
        }
      }

      & + & {
        ${Container} {
          padding-top: 30px;
          margin-top: -30px;

          @media ${media.sm} {
            padding-top: 45px;
            margin-top: -55px;
          }
        }
      }
    `}
`

const Header = styled.div`
  h2,
  h3 {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  p,
  ul,
  ol {
    margin-top: 0;
  }

  .table table {
    margin: 0 0 3em;
  }
`

const TextWithHeaderLeft = (props) => {
  return (
    <Wrapper
      compact={props.style === "compact"}
      className={`has--background-${props.backgroundColor || "white"}`}
    >
      <Container>
        <Row>
          <Col md={6} lg={5} xl={4}>
            {props.header && (
              <Header>
                <Heading type={props.headerLevel} className={props.headerColor}>
                  {props.header}
                </Heading>
              </Header>
            )}
          </Col>
          <Col md={6} lg={7} xl={8}>
            <TextStyles
              alignment={props.alignment}
              textColor={props.textColor}
              textSize={props.textSize}
            >
              <Content>
                {props.subheader && (
                  <Heading type="h4" subHeader className={props.subheaderColor}>
                    {props.subheader}
                  </Heading>
                )}
                {renderContentfulBlocks(props.text, { ...props })}
              </Content>
            </TextStyles>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default TextWithHeaderLeft
