import React from "react"
import { graphql } from "gatsby"
import { IntlContextConsumer } from "gatsby-plugin-react-intl"

import { storeCampaignCookie } from "../helpers/userTracker"
import Page from "../layouts/Page"
import Seo from "../components/Seo"

import { RenderContentfulSections } from "../helpers/contentful"
import { getUrlParam } from "../helpers/"

export const sectionQuery = graphql`
  fragment ContentfulImageData on ContentfulAsset {
    __typename
    contentful_id
    description
    node_locale
    svg {
      content
    }
    file {
      contentType
      details {
        image {
          width
          height
        }
      }
    }
    localFile {
      publicURL
    }
    gatsbyImageData(layout: CONSTRAINED)
  }
  fragment Cta on ContentfulCta {
    __typename
    contentful_id
    extraText {
      extraText
    }
    header
    linkText
    linkUrl
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text {
      text
    }
  }
  fragment Hero on ContentfulHero {
    __typename
    backgroundColor
    buttonText
    buttonUrl
    contentful_id
    finePrint {
      finePrint
    }
    header
    image {
      ...ContentfulImageData
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 745
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
    imageFit
    imagePosition
    includeAppStoreBadges
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text {
      text
    }
  }
  fragment MarkdownBlock on ContentfulMarkdownBlock {
    __typename
    alignment
    backgroundColor
    contentful_id
    fullWidth
    header
    headerColor
    headerLevel
    padding
    style
    subheader
    subheaderColor
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    textColor
    textSize
  }
  fragment SpecialElement on ContentfulSpecialElement {
    __typename
    contentful_id
    element
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
  # fragment Stage on ContentfulStage {
  #   id
  #   buttonText
  #   buttonUrl
  #   finePrint {
  #     finePrint
  #   }
  #   header
  #   imageFit
  #   image {
  #     ...ContentfulImageData
  #     localFile {
  #       childImageSharp {
  #         gatsbyImageData(
  #           width: 675
  #           layout: CONSTRAINED
  #           transformOptions: { fit: CONTAIN }
  #         )
  #       }
  #     }
  #   }
  #   text {
  #     text
  #   }
  #   sys {
  #     contentType {
  #       sys {
  #         id
  #       }
  #     }
  #   }
  # }
  fragment FaqCollection on ContentfulFaqCollection {
    __typename
    contentful_id
    faqs {
      id
      contentful_id
      node_locale
      question
      answer {
        answer
        childMarkdownRemark {
          html
        }
      }
      keywords
    }
    node_locale
    sortOrder
    showOnFaqPage
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    title
  }

  fragment TextAndImageBlock on ContentfulTextAndImageBlock {
    __typename
    alignment
    backgroundColor
    contentful_id
    header
    headerColor
    headerLevel
    image {
      ...ContentfulImageData
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 705
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
    imageAlignment
    layout
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text {
      raw
      references {
        # ... on ContentfulAsset {
        #   ...ContentfulImageData
        # }
        ... on ContentfulMarkdownBlock {
          ...MarkdownBlock
        }
        # ... on ContentfulSpecialElement {
        #   ...SpecialElement
        # }
      }
    }
    textColor
    textSize
  }
  # fragment TextBlockWithAside on ContentfulTextBlockWithAside {
  #   __typename
  #   alignment
  #   anchorId
  #   aside {
  #     aside
  #   }
  #   backgroundColor
  #   contentful_id
  #   header
  #   headerColor
  #   headerLevel
  #   linkText
  #   linkUrl
  #   subheader
  #   subheaderColor
  #   subheaderLevel
  #   sys {
  #     contentType {
  #       sys {
  #         id
  #       }
  #     }
  #   }
  #   text {
  #     raw
  #   }
  #   textColor
  #   textSize
  # }
  fragment TextBlock on ContentfulTextBlock {
    __typename
    alignment
    anchorId
    backgroundColor
    contentful_id
    fullWidth
    header
    headerColor
    headerLevel
    linkText
    linkUrl
    padding
    subheader
    subheaderColor
    subheaderLevel
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text {
      raw
      references {
        ... on ContentfulAsset {
          ...ContentfulImageData
        }
        ... on ContentfulMarkdownBlock {
          ...MarkdownBlock
        }
        ... on ContentfulSpecialElement {
          ...SpecialElement
        }
      }
    }
    textColor
    textSize
  }
  fragment TextThreeColumns on ContentfulTextThreeColumns {
    __typename
    alignment
    backgroundColor
    buttonText
    buttonUrl
    columnStyle
    contentful_id
    header
    headerColor
    headerLevel
    subheader
    subheaderColor
    subheaderLevel
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    text1 {
      raw
      references {
        ... on ContentfulAsset {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    text2 {
      raw
      references {
        ... on ContentfulAsset {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    text3 {
      raw
      references {
        ... on ContentfulAsset {
          ...ContentfulImageData
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    textColor
    textSize
  }
  fragment Notice on ContentfulNotice {
    __typename
    hideOnPages
    contentful_id
    id
    isCloseable
    text {
      childMarkdownRemark {
        html
      }
    }
  }
  # fragment TextTwoColumns on ContentfulTextTwoColumns {
  #   alignment
  #   backgroundColor
  #   buttonText
  #   buttonUrl
  #   columnStyle
  #   header
  #   headerColor
  #   headerLevel
  #   subheader
  #   subheaderColor
  #   subheaderLevel
  #   sys {
  #     contentType {
  #       sys {
  #         id
  #       }
  #     }
  #   }
  #   text1 {
  #     raw
  #     references {
  #       ... on ContentfulAsset {
  #         ...ContentfulImageData
  #       }
  #     }
  #   }
  #   text2 {
  #     raw
  #     references {
  #       ... on ContentfulAsset {
  #         ...ContentfulImageData
  #       }
  #     }
  #   }
  #   textColor
  #   textSize
  # }
  # fragment TextWithHeaderLeft on ContentfulTextWithHeaderLeft {
  #   alignment
  #   backgroundColor
  #   header
  #   headerColor
  #   headerLevel
  #   style
  #   subheader
  #   subheaderColor
  #   sys {
  #     contentType {
  #       sys {
  #         id
  #       }
  #     }
  #   }
  #   text {
  #     raw
  #     references {
  #       ... on ContentfulAsset {
  #         ...ContentfulImageData
  #       }
  #     }
  #   }
  #   textColor
  #   textSize
  # }
`

export const pageQuery = graphql`
  query contentfulPageQuery($slug: String, $locale: String) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      node_locale
      browserTitle
      metaDescription
      metaKeywords
      noIndex
      campaignIdentifier
      layout
      sections {
        ... on ContentfulCta {
          ...Cta
        }
        ... on ContentfulFaqCollection {
          ...FaqCollection
        }
        ... on ContentfulHero {
          ...Hero
        }
        ... on ContentfulMarkdownBlock {
          ...MarkdownBlock
        }
        ... on ContentfulSpecialElement {
          ...SpecialElement
        }
        # ... on ContentfulStage {
        #   ...Stage
        # }
        ... on ContentfulTextAndImageBlock {
          ...TextAndImageBlock
        }
        ... on ContentfulTextBlock {
          ...TextBlock
        }
        # ... on ContentfulTextBlockWithAside {
        #   ...TextBlockWithAside
        # }
        ... on ContentfulTextThreeColumns {
          ...TextThreeColumns
        }
      }
      theme
    }
  }
`

const ContentfulPage = (props) => {
  const page = props.data.contentfulPage

  // Store campaignIdentifier to cookie "bsdex_campaign"
  React.useEffect(() => {
    storeCampaignCookie(page.campaignIdentifier)
  }, [storeCampaignCookie, page.campaignIdentifier])

  // If we're on the referrals landing page and we have
  // a ?ref= query parameter, save it to cookie "bsdex_campaign"
  React.useEffect(() => {
    if (page.slug === "lp/kunden-werben-kunden") {
      const referralCode = getUrlParam("ref")
      storeCampaignCookie(referralCode)
    }
  }, [page.slug])

  return (
    <>
      <Seo
        title={page.browserTitle}
        description={page.metaDescription && page.metaDescription}
        keywords={page.metaKeywords && page.metaKeywords}
        socialShareTitleOpengraph={
          page.socialShareTitleOpengraph ? page.socialShareTitleOpengraph : null
        }
        socialShareDescriptionOpengraph={
          page.socialShareDescriptionOpengraph
            ? page.socialShareDescriptionOpengraph
            : null
        }
        noIndex={page.noIndex}
        lang={props.currentLanguage}
      />
      {page.sections && <RenderContentfulSections sections={page.sections} />}
    </>
  )
}

const ContentfulPageTemplate = ({ data }) => {
  return (
    <IntlContextConsumer>
      {({ language: currentLanguage }) => (
        <Page
          layout={data.contentfulPage.layout}
          theme={data.contentfulPage.theme}
          pageId={data.contentfulPage.slug}
        >
          <ContentfulPage data={data} currentLanguage={currentLanguage} />
        </Page>
      )}
    </IntlContextConsumer>
  )
}

export default ContentfulPageTemplate
