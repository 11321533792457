import React from "react"
import styled from "styled-components"

const BoxContainer = styled.div`
  border-radius: 4px;
  box-shadow: ${({ theme }) =>
    theme.name === "dark"
      ? `inset 0 0 0 1px ${theme.borderColor}`
      : theme.boxShadowMedium};
  transition: box-shadow ${({ theme }) => theme.transitionTime};
`

const Box = ({ className, children }) => {
  return <BoxContainer className={className}>{children}</BoxContainer>
}

export default Box
