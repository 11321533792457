import React from "react"
import styled, { css } from "styled-components"
import { Container as StyledContainer } from "./Grid"
import { media } from "../styles"

import Snippet from "../components/Snippet"
import LogoBoerseStuttgart from "../images/partners/logorow-boerse_stuttgart.inline.svg"
import LogoAxelSpringer from "../images/partners/logorow-axel_springer.inline.svg"
import LogoFinanzenNet from "../images/partners/logorow-finanzen_net.inline.svg"
import LogoSBIHoldings from "../images/partners/logorow-sbi_holdings.inline.svg"
import LogoSolaris from "../images/partners/logorow-solaris.inline.svg"

const Container = styled(StyledContainer)`
  padding-bottom: 20px;

  @media ${media.tablet} {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @media ${media.tabletLandscape} {
    padding-bottom: 85px;
  }

  @media ${media.desktop} {
    padding-bottom: 95px;
    padding-top: 35px;
  }
`

const Header = styled.div`
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
  text-align: center;
  margin-bottom: 40px;

  @media ${media.tablet} {
    text-align: left;
    margin-bottom: 20px;
  }

  @media ${media.tabletLandscape} {
    font-size: 16px;
    margin-bottom: 35px;
  }
`

const Logos = styled.ul`
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  opacity: ${({ theme }) => (theme.name === "dark" ? 0.5 : 1)};
  padding: 0 10px;

  @media ${media.sm} {
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 -15px;
    padding: 0;
  }
`

const Logo = styled.li`
  align-items: center;
  display: flex;
  height: 50px;
  margin: 0 0 15px;
  width: 45%;
  padding: 0 15px;

  @media ${media.sm} {
    width: auto;
  }

  @media ${media.tabletPortrait} {
    max-width: 150px;
  }

  @media ${media.desktop} {
    max-width: 200px;
  }

  ${(props) =>
    props.smaller1 &&
    css`
      @media ${media.tabletPortrait} {
        max-width: 120px;
      }

      @media ${media.desktop} {
        max-width: 150px;
      }
    `}

  ${(props) =>
    props.smaller2 &&
    css`
      @media ${media.tabletPortrait} {
        max-width: 140px;
      }

      @media ${media.tabletPortrait} {
        max-width: 170px;
      }
    `}

  img {
    max-width: 100%;

    /* filter: saturate(0%) brightness(70%) contrast(1000%); */
    /* max-height: 100%;
    object-fit: contain !important; */
  }
`

const PartnerLogosRow = () => {
  return (
    <Container>
      <Header>
        <Snippet id="3OIyhXhMK58EBadn0epFrG" field="header" />
      </Header>
      <Logos>
        <Logo smaller1>
          <LogoBoerseStuttgart alt="Börse Stuttgart" />
        </Logo>
        <Logo smaller2>
          <LogoFinanzenNet alt="Finanzen.Net" />
        </Logo>
        <Logo>
          <LogoAxelSpringer alt="Axel Springer" />
        </Logo>
        <Logo>
          <LogoSBIHoldings alt="SBI Holdings" />
        </Logo>
        <Logo>
          <LogoSolaris alt="Solaris" />
        </Logo>
      </Logos>
    </Container>
  )
}

export default PartnerLogosRow
