import React from "react"
import styled from "styled-components"

import { media } from "../styles"

const CustomCheckbox = styled.div`
  display: block;
  background: ${({ theme }) => theme.inputBackground};
  min-height: 24px;
  margin-bottom: 20px;
  padding: 20px 20px 20px 50px;
  position: relative;
  overflow: hidden;

  @media ${media.tablet} {
    margin-bottom: 30px;
    padding: 20px 20px 20px 50px;
  }
`

const Label = styled.label`
  cursor: pointer;
  font-size: 13px;
  position: relative;

  a {
    color: ${({ theme }) => theme.brandPrimaryDark};

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${media.tablet} {
    font-size: 16px;
  }

  &::before {
    background: center no-repeat;
    background-size: 14px 14px;
    border: 2px solid ${({ theme }) => theme.inputBorder};
    box-sizing: border-box;
    content: "";
    display: block;
    height: 20px;
    left: -28px;
    position: absolute;
    top: 0;
    width: 20px;

    @media ${media.tablet} {
      height: 22px;
      left: -30px;
      width: 22px;
    }
  }
`

const CheckboxField = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 0;

  &:checked + label::before {
    background-image: ${({ theme }) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='${String(
        theme.brandLinksHover
      ).replace(
        "#",
        "%23"
      )}' fill-rule='evenodd' d='M0 0h10v10H0z'/%3E%3C/svg%3E")`};
    border-color: ${({ theme }) => theme.brandLinksHover};
  }
`

const Checkbox = (props) => {
  return (
    <CustomCheckbox className={props.className}>
      <CheckboxField
        type="checkbox"
        checked={props.checked}
        name={props.name}
        id={`${props.prefix}${props.name}`}
        required={props.required}
      />
      <Label htmlFor={`${props.prefix}${props.name}`}>{props.label}</Label>
    </CustomCheckbox>
  )
}

export default Checkbox
