import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-react-intl"

import { Container } from "../components/Grid"
import StyledBox from "../components/Box"

import { media } from "../styles"

const Box = styled(StyledBox)`
  margin: 0 0 40px;

  @media ${media.md} {
    margin: 0 0 60px;
  }

  @media ${media.lg} {
    margin: 0 0 80px;
    padding: 45px 30px;
  }

  @media ${media.xl} {
    margin: 0 0 100px;
    padding: 45px;
  }

  @media ${media.lgDown} {
    box-shadow: none;
  }
`

const LastUpdated = styled.div`
  margin: -20px 0 60px;
  text-align: center;
`

const TradingParticipantsTable = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      allContentfulTradingParticipant(
        filter: {
          showInParticipantsTable: { ne: false }
          node_locale: { eq: "en" }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          contentful_id
          city
          country
          legalEntityIdentifier
          memberId
          name
          postalCode
          street
          url
          updatedAt(formatString: "yyyy-MM-DD")
          formattedDate: updatedAt(formatString: "DD.MM.yyyy")
        }
      }
    }
  `)
  const participants = data.allContentfulTradingParticipant.nodes
  // get update date of most recently edited entry
  const lastUpdate = [...participants].sort((a, b) =>
    b.updatedAt.localeCompare(a.updatedAt)
  )[0].formattedDate

  return (
    <Container>
      <LastUpdated>
        <p>Version: {lastUpdate}</p>
      </LastUpdated>
      <Box>
        <div className="table table-simple table-large table-nomargin table-scroll-lg-down">
          <table>
            <thead>
              <tr>
                <th>
                  {intl.formatMessage({
                    id: "tradingParticipants.tradingParticipant",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "tradingParticipants.legalEntityIdentifier",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "tradingParticipants.address",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "tradingParticipants.memberId",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "tradingParticipants.website",
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {participants.map((participant) => (
                <tr key={participant.contentful_id}>
                  <td>
                    <strong>{participant.name}</strong>
                  </td>
                  <td>{participant.legalEntityIdentifier}</td>
                  <td>
                    {participant.street && (
                      <>
                        {participant.street}
                        <br />
                      </>
                    )}
                    {participant.postalCode} {participant.city}
                    {(participant.postalCode || participant.city) && <br />}
                    {participant.country}
                  </td>
                  <td>{participant.memberId}</td>
                  <td>
                    {participant.url && (
                      <a
                        href={participant.url}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        {participant.url}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
    </Container>
  )
}

export default TradingParticipantsTable
