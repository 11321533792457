import React from "react"
import Layout from "./Layout"

const Page = (props) => {
  return (
    <Layout
      hideLangSwitcher={props.hideLandswitcher}
      layout={props.layout}
      overlayHeight={props.overlayHeight}
      pageId={props.pageId}
      theme={props.theme}
      hasHeader={props.hasHeader === true || props.hasHeader === undefined}
      hasFooter={props.hasFooter === true || props.hasFooter === undefined}
    >
      {props.children}
    </Layout>
  )
}

export default Page
