import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Location } from "@reach/router"
import { useIntl } from "gatsby-plugin-react-intl"

import { Container, Row, Col } from "../components/Grid"
import Heading from "../components/Heading"
import FAQItem from "../components/FAQItem"
import Snippet from "../components/Snippet"
import Input from "../components/Input"
import { ScreenReaderText } from "../components/Accessibility"

import { slugify, removeHash, romanize } from "../helpers"
import { media, mixins } from "../styles"
import CloseSVG from "../images/icons/close.inline.svg"
import SearchSVG from "../images/icons/search.inline.svg"

const Wrapper = styled.div`
  padding: 10px 0;
`

const Category = styled.div``

const CategoryHeader = styled(Heading).attrs((props) => ({ type: "h3" }))`
  margin-bottom: 0;
  margin-top: 75px;

  @media ${media.mdDown} {
    font-size: 20px;
    line-height: 28px;
  }
`

const SearchBox = styled.div`
  background: ${({ theme }) => theme.white};
  margin: 0 0 30px;
  padding: 20px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
`

const InputWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 500px;

  input {
    margin: 0;
    padding-left: 40px;
    padding-right: 45px;
  }
`

const SearchIcon = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.brandSecondaryDark};
  display: flex;
  height: 100%;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;

  svg {
    height: auto;
    width: 24px;
  }
`

const SearchReset = styled.button`
  align-items: center;
  appearance: none;
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.brandSecondaryDark};
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    color: ${({ theme }) => theme.brandPrimaryDark};
  }

  svg {
    display: block;
    height: auto;
    width: 16px;
  }
`

const MissingTopic = styled.div`
  margin-top: 75px;
  text-align: center;
`

const ResultsHeader = styled.h2`
  ${mixins.title4};
  max-height: 100px;
  transition: opacity ${({ theme }) => theme.transitionTime},
    padding ${({ theme }) => theme.transitionTime},
    max-height ${({ theme }) => theme.transitionTime};

  @media ${media.sm} {
    padding: 30px 0;
  }

  ${(props) =>
    props.hide &&
    css`
      max-height: 0px;
      opacity: 0;
      padding: 0 !important;
    `}
`

const NoResults = styled.div``

const FaqList = () => {
  const [openSections, setOpenSections] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const minSearchLength = 3

  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaqCollection(
        filter: { showOnFaqPage: { eq: true } }
        sort: { fields: sortOrder }
      ) {
        nodes {
          ...FaqCollection
        }
      }
    }
  `)

  const faqCollections = data.allContentfulFaqCollection.nodes.filter(
    (node) => node.node_locale === intl.locale
  )

  const handleSearch = (e) => {
    const value = e.target.value
    setSearchTerm(value)
  }

  const clearSearch = () => {
    setSearchTerm("")
  }

  // check URL for deeplink to an FAQ
  useEffect(() => {
    const id = window.location.hash.substring(1)
    // Remove hash due to Safari interfering with scroll position
    removeHash()
    if (id) {
      const el = document.querySelector(`#${id}`)
      setOpenSections([...openSections, id])
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [])

  useEffect(() => {
    if (searchTerm.length >= minSearchLength) {
      let results = []
      const searchTermFixed = searchTerm.toLowerCase()
      faqCollections.forEach((faqCollection) => {
        faqCollection.faqs.forEach((faq) => {
          const keywords = faq.keywords?.toString().toLowerCase()
          if (
            faq.question.toLowerCase().indexOf(searchTermFixed) > -1 ||
            faq.answer.answer.toLowerCase().indexOf(searchTermFixed) > -1 ||
            keywords?.indexOf(searchTermFixed) > -1
          ) {
            results.push(slugify(`${faqCollection.title}-${faq.question}`))
          } else {
            results.filter(
              (item) =>
                item !== slugify(`${faqCollection.title}-${faq.question}`)
            )
          }
        })
      })

      setSearchResults(results)
    } else {
      setSearchResults([])
    }
  }, [searchTerm])

  return (
    <Location>
      {({ location }) => (
        <Wrapper>
          <Container>
            <Row>
              <Col lg={10} lgOffset={1}>
                <SearchBox>
                  <InputWrapper>
                    <SearchIcon aria-hidden="true">
                      <SearchSVG />
                    </SearchIcon>
                    <Input
                      name="search"
                      label={intl.formatMessage({
                        id: "support.search.label",
                      })}
                      hideLabel
                      placeholder={intl.formatMessage({
                        id: "support.search.searchFaqs",
                      })}
                      onChange={handleSearch}
                      prefix="faq-"
                      type="text"
                      value={searchTerm}
                    />
                    {searchTerm && (
                      <SearchReset onClick={clearSearch}>
                        <CloseSVG />
                        <ScreenReaderText>
                          {intl.formatMessage({ id: "support.search.clear" })}
                        </ScreenReaderText>
                      </SearchReset>
                    )}
                  </InputWrapper>
                </SearchBox>
                <ResultsHeader hide={searchTerm.length < 3}>
                  {intl.formatMessage({ id: "support.search.resultsHeader" })}:{" "}
                  <strong>{searchTerm}</strong>
                </ResultsHeader>
                {(searchResults.length !== 0 && searchTerm.length >= 3) ||
                searchTerm.length < 3 ? (
                  faqCollections.map(
                    (faqCollection) =>
                      faqCollection.faqs.length > 0 && (
                        <Category key={faqCollection.contentful_id}>
                          {searchResults.length === 0 && (
                            <CategoryHeader>
                              {faqCollection.sortOrder &&
                                `${romanize(faqCollection.sortOrder)}. `}
                              {faqCollection.title}
                            </CategoryHeader>
                          )}
                          {faqCollection.faqs.map(
                            (faq, i) =>
                              (searchResults.length === 0 ||
                                searchResults.find(
                                  (item) =>
                                    item ===
                                    slugify(
                                      `${faqCollection.title}-${faq.question}`
                                    )
                                )) && (
                                <FAQItem
                                  faq={faq}
                                  highlightText={
                                    searchResults.length !== 0 &&
                                    searchTerm.length >= minSearchLength
                                      ? searchTerm.toLowerCase()
                                      : null
                                  }
                                  id={slugify(
                                    `${faqCollection.title}-${faq.question}`
                                  )}
                                  isCollapsible={searchResults.length === 0}
                                  isOpen={
                                    searchResults.length !== 0 ||
                                    openSections.find(
                                      (item) =>
                                        item ===
                                        slugify(
                                          `${faqCollection.title}-${faq.question}`
                                        )
                                    )
                                  }
                                  key={i}
                                  open={
                                    location.hash === `#${faq.contentful_id}`
                                  }
                                />
                              )
                          )}
                        </Category>
                      )
                  )
                ) : (
                  <NoResults>
                    {intl.formatMessage({ id: "support.search.noResults" })}
                  </NoResults>
                )}
              </Col>
            </Row>
            <MissingTopic>
              <Snippet id="7axwVFwlBOk0rdBhWkmpIk" field="text" renderHtml />
            </MissingTopic>
          </Container>
        </Wrapper>
      )}
    </Location>
  )
}

export default FaqList
