import React from "react"
import styled from "styled-components"

import animation from "../video/bsdex_animation.mp4"
import poster from "../video/bsdex_animation.jpg"
import tabletFrame from "../images/illustrations/tablet_frame.png"

import { media } from "../styles"

const DeviceWrapper = styled.div`
  max-width: 600px;
  margin: 20px 0;

  @media ${media.sm} {
    margin: 45px 0;
  }
`

const DeviceContainer = styled.div`
  padding-top: 66.4%;
  position: relative;
`

const DeviceFrame = styled.div`
  background: url(${tabletFrame});
  background-size: 100% auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const DeviceViewport = styled.div`
  border-radius: 2%;
  height: 89.15%;
  left: 4.9%;
  overflow: hidden;
  position: relative;
  top: 5.5%;
  width: 90.25%;

  video {
    width: 100%;
  }
`

const Device = () => {
  return (
    <DeviceWrapper>
      <DeviceContainer>
        <DeviceFrame>
          <DeviceViewport
            dangerouslySetInnerHTML={{
              __html: `
          <video
            autoplay
            loop
            muted
            playsinline
            poster="${poster}"
            src="${animation}"
          />
        `,
            }}
          />
        </DeviceFrame>
      </DeviceContainer>
    </DeviceWrapper>
  )
}

export default Device
