import React from "react"
import styled from "styled-components"
import { Location } from "@reach/router"

import { Container, Row, Col } from "../components/Grid"
import Heading from "../components/Heading"
import FAQItem from "../components/FAQItem"

import { slugify } from "../helpers"

import { media } from "../styles"

const Wrapper = styled.div`
  padding: 10px 0;

  @media ${media.lg} {
    padding: 70px 0;
  }
`

const Header = styled.div`
  margin-bottom: 30px;
  text-align: center;

  @media ${media.md} {
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 10px;
  }
`

const FaqCollection = ({ title, faqs }) => {
  return (
    <Location>
      {({ location }) =>
        faqs?.length >= 1 && (
          <Wrapper>
            <Container>
              <Header>
                <Heading type="h2" className="h3">
                  {title}
                </Heading>
              </Header>
              <Row>
                <Col lg={10} lgOffset={1}>
                  {faqs.map((faq, i) => (
                    <FAQItem
                      faq={faq}
                      id={slugify(faq.question)}
                      isCollapsible
                      isOpen={location.hash === `#${slugify(faq.question)}`}
                      key={i}
                    />
                  ))}
                </Col>
              </Row>
            </Container>
          </Wrapper>
        )
      }
    </Location>
  )
}

export default FaqCollection
