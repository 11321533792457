import React from "react"
import styled from "styled-components"

import { Container, Row, Col } from "../components/Grid"
import Button from "../components/Button"
import Snippet from "../components/Snippet"

import Config from "../helpers/config"
import { media } from "../styles"

const TableContainer = styled.div`
  overflow: auto;
`

const Box = styled.div`
  margin: 0 0 45px;

  @media ${media.md} {
    box-shadow: ${({ theme }) => theme.boxShadowMedium};
    border-radius: 4px;
    padding: 30px;
  }

  @media ${media.lg} {
    padding: 45px;
  }
`

const PriceTableEl = styled.table`
  border-collapse: collapse;
  font-size: 16px;
  line-height: 1.25;
  margin: 0 0 ${({ theme }) => theme.spacingXs}px;
  table-layout: fixed;
  text-align: left;
  width: 100%;

  @media ${media.sm} {
    margin: 0 0 ${({ theme }) => theme.spacing * 2}px;
  }

  @media ${media.lg} {
    font-size: 20px;
  }

  th,
  td {
    padding: 18px 0;
    text-align: left;
    vertical-align: top;
  }

  thead th {
    border-bottom: 2px solid ${({ theme }) => theme.textColor};

    @media ${media.sm} {
      font-size: 20px;
    }
  }

  tbody {
    td,
    th {
      border-bottom: 2px solid ${({ theme }) => theme.borderColor};
    }

    th {
      font-weight: normal;
    }

    td {
      width: 25%;
    }
  }

  small {
    color: inherit;
    display: block;
    font-size: 10px;
    line-height: 1.5;
    margin-top: 5px;

    @media ${media.sm} {
      font-size: 12px;
    }

    @media ${media.lg} {
      font-size: 16px;
    }
  }
`

const HeadCol = styled.th`
  width: 46%;

  @media ${media.sm} {
    width: 52%;
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin: 20px 0;

  @media ${media.sm} {
    margin: 60px 0;
  }
`

export const PriceTable = ({ signupButton }) => {
  const pricetableHeaderExecute = "2lhWxAsLRFKmztuDVgmR83"
  const pricetableHeaderMakerorder = "1UbbsVp6TplXYsVGQjPq6e"
  const pricetableHeaderTakerorder = "jNlG4U1C7UGRszZPVlXeU"
  const pricetableHeaderRelativefee = "7tMbthadP5qST8hNck0MTZ"
  const pricetableMakerorderRelativefee = "3rVAughzv0AEjvCiNyifif"
  const pricetableTakerorderRelativefee = "5c9plfx4mceqYSPqa41Ac8"
  const pricetableHeaderMinimumfee = "107hznNSKuSSodfBPGsFOe"
  const pricetableMakerorderMinimumfee = "6Gcn1wH86JDXw9UHRLoj5i"
  const pricetableTakerorderMinimumfee = "5NDw1RiBA9nQX3MY0VsoZT"
  const noOtherFees = "1PRmmEa4nsjiSCsaOEsHLs"
  const signupCTAButton = "7DF014vBSdVde3MDJPlAe0"

  return (
    <Container>
      <Row>
        <Col lg={10} lgOffset={1}>
          <Box>
            <TableContainer>
              <PriceTableEl>
                <thead>
                  <tr>
                    <HeadCol>
                      <Snippet id={pricetableHeaderExecute} field="header" />
                    </HeadCol>
                    <th>
                      <Snippet id={pricetableHeaderMakerorder} field="header" />
                    </th>
                    <th>
                      <Snippet id={pricetableHeaderTakerorder} field="header" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <HeadCol>
                      <Snippet
                        id={pricetableHeaderRelativefee}
                        field="header"
                        renderHtml
                      />
                    </HeadCol>
                    <td>
                      <Snippet
                        id={pricetableMakerorderRelativefee}
                        field="text"
                        renderHtml
                      />
                    </td>
                    <td>
                      <Snippet
                        id={pricetableTakerorderRelativefee}
                        field="text"
                        renderHtml
                      />
                    </td>
                  </tr>
                  <tr>
                    <HeadCol>
                      <Snippet
                        id={pricetableHeaderMinimumfee}
                        field="header"
                        renderHtml
                      />
                    </HeadCol>
                    <td>
                      <Snippet
                        id={pricetableMakerorderMinimumfee}
                        field="text"
                        renderHtml
                      />
                    </td>
                    <td>
                      <Snippet
                        id={pricetableTakerorderMinimumfee}
                        field="text"
                        renderHtml
                      />
                    </td>
                  </tr>
                </tbody>
              </PriceTableEl>
            </TableContainer>
            <Snippet id={noOtherFees} field="text" renderHtml />
          </Box>

          {signupButton && (
            <ButtonRow>
              <Button
                href={Config().linkPlatformSignup}
                kind="primary"
                size="large"
              >
                <Snippet id={signupCTAButton} field="linkText" />
              </Button>
            </ButtonRow>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default PriceTable
