import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import axios from "axios"
import { useIntl } from "gatsby-plugin-react-intl"
import { formatDistanceToNow } from "date-fns"
import germanLocale from "date-fns/locale/de"
import defaultLocale from "date-fns/locale/en-US"
import InnerHTML from "dangerously-set-html-content"

import { isBrowser } from "../helpers"
import { media, mixins } from "../styles"

const Wrapper = styled.div`
  max-width: 1020px;
  margin: 20px auto 5px;

  @media ${media.tablet} {
    margin: 20px auto;
  }

  @media ${media.tabletLandscape} {
    margin: 40px auto 65px;
  }

  @media ${media.desktop} {
    margin: 40px auto 55px;
  }

  img {
    max-width: 100%;
  }
`

const LastUpdate = styled.div`
  ${mixins.copySmall}
  color: ${({ theme }) => theme.textColorLight};
  margin-top: 10px;
  text-align: center;
`

const PriceTicker = ({ format }) => {
  const [loading, setLoading] = useState(true)
  const [widget, setWidget] = useState()
  const [error, setError] = useState()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      priceTickerFallback_de: file(name: { eq: "priceTickerFallback_de" }) {
        lastUpdated: modifiedTime
        name
        fields {
          content
        }
      }
      priceTickerFallback_en: file(name: { eq: "priceTickerFallback_en" }) {
        lastUpdated: modifiedTime
        name
        fields {
          content
        }
      }
      priceTickerLoading_de: file(name: { eq: "priceTickerLoading_de" }) {
        name
        fields {
          content
        }
      }
      priceTickerLoading_en: file(name: { eq: "priceTickerLoading_en" }) {
        name
        fields {
          content
        }
      }
    }
  `)

  const fetchPriceTicker = async () => {
    try {
      const response = await axios.get(
        `${process.env.GATSBY_WIDGET_SERVER}/${intl.locale}/markets/`
      )
      setWidget(response.data)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
      console.log(error.response)
    }
  }

  useEffect(() => {
    fetchPriceTicker()
  }, [])

  const loadingWidget =
    intl.locale === "de"
      ? data.priceTickerLoading_de.fields.content
      : data.priceTickerLoading_en.fields.content

  return (
    <Wrapper>
      {loading ? (
        isBrowser() ? (
          <>
            {/* Fallback because React drops the <style> in the browser */}
            <InnerHTML html={loadingWidget} />
          </>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: loadingWidget,
            }}
          />
        )
      ) : (
        <>
          {widget ? <div dangerouslySetInnerHTML={{ __html: widget }} /> : null}
          {error ? (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    intl.locale === "de"
                      ? data.priceTickerFallback_de.fields.content
                      : data.priceTickerFallback_en.fields.content,
                }}
              />
              <LastUpdate>
                {intl.formatMessage({ id: "lastUpdate" })}{" "}
                {formatDistanceToNow(
                  new Date(data.priceTickerFallback_de.lastUpdated),
                  {
                    addSuffix: true,
                    locale: intl.locale === "de" ? germanLocale : defaultLocale,
                  }
                )}
              </LastUpdate>
            </>
          ) : null}
        </>
      )}
    </Wrapper>
  )
}

export default PriceTicker
