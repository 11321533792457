import React from "react"
import styled, { css } from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"
import Button from "../components/Button"

import { renderContentfulBlocks } from "../helpers/contentful"

import { media } from "../styles"

const Container = styled(StyledContainer)`
  padding-top: 40px;

  @media ${media.tablet} {
    padding-top: 50px;
  }

  @media ${media.tabletLandscape} {
    padding-top: 80px;
  }

  .image--svg {
    margin-bottom: 20px;

    @media ${media.tablet} {
      margin-bottom: 15px;
    }

    @media ${media.tabletLandscape} {
      margin-bottom: 30px;
    }
  }
`

const TextCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.spacingXs * 2.5}px;

  @media ${media.sm} {
    margin-bottom: ${({ theme }) => theme.spacing}px;
  }
`

const Content = styled.div`
  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`

const Wrapper = styled.div`
  ${(props) =>
    props.columnStyle === "splitters" &&
    css`
      ${Header} {
        @media ${media.tablet} {
          margin-bottom: 40px;
        }

        @media ${media.tabletLandscape} {
          margin-bottom: 80px;
        }
      }

      ${Content} ${TextCol} {
        @media ${media.mdDown} {
          &:first-child {
            border-bottom: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
            padding-bottom: 45px;
            margin-bottom: 10px;
          }

          &:last-child {
            border-top: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
            padding-top: 15px;
          }
        }

        @media ${media.tablet} {
          padding: 5px 15px 25px;

          &:first-child {
            border-right: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
          }

          &:last-child {
            border-left: 1px solid
              rgba(${({ theme }) => theme.brandSecondaryMediumRGB}, 0.2);
          }
        }

        @media ${media.tabletLandscape} {
          padding: 45px 15px;
        }

        @media ${media.desktop} {
          padding: 45px 35px;
        }
      }
    `}
`

const Header = styled.div`
  margin-bottom: 40px;
  text-align: center;

  @media ${media.tablet} {
    margin-bottom: 60px;
  }

  @media ${media.tabletLandscape} {
    margin-bottom: 90px;
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: -15px;
  margin-bottom: 55px;

  .button {
    margin-bottom: 0;
  }

  @media ${media.tablet} {
    margin-top: 35px;
  }

  @media ${media.tabletLandscape} {
    margin-top: 45px;
    margin-bottom: 95px;
  }

  @media ${media.desktop} {
    margin-bottom: 70px;
  }
`

const Text3Cols = (props) => {
  return (
    <Wrapper
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
      columnStyle={props.columnStyle}
    >
      <Container>
        {props.header && (
          <Row>
            <Col lg={10} lgOffset={1} xl={8} xlOffset={2}>
              <Header>
                <Heading type={props.headerLevel} className={props.headerColor}>
                  {props.header}
                </Heading>
                {props.subheader && (
                  <Heading type="h4" subHeader className={props.subheaderColor}>
                    {props.subheader}
                  </Heading>
                )}
              </Header>
            </Col>
          </Row>
        )}
        <Content>
          <TextStyles
            alignment={props.alignment}
            textColor={props.textColor}
            textSize={props.textSize}
          >
            <Row>
              <TextCol sm={4}>
                {renderContentfulBlocks(props.text1, { ...props })}
              </TextCol>
              <TextCol sm={4}>
                {renderContentfulBlocks(props.text2, { ...props })}
              </TextCol>
              <TextCol sm={4}>
                {renderContentfulBlocks(props.text3, { ...props })}
              </TextCol>
            </Row>
          </TextStyles>
        </Content>
        {props.buttonText && props.buttonUrl && (
          <ButtonRow>
            <Button href={props.buttonUrl} kind="primary" size="large">
              {props.buttonText}
            </Button>
          </ButtonRow>
        )}
      </Container>
    </Wrapper>
  )
}

export default Text3Cols
