import React from "react"

import Snippet from "../components/Snippet"
import CTA from "../components/CTA"

const InstitutionForm = () => {
  return (
    <CTA
      header={Snippet({ id: "2VfvaHIidPYwxXrLktmwu2", field: "header" })}
      text={Snippet({ id: "2VfvaHIidPYwxXrLktmwu2", field: "text" })}
      linkText={Snippet({
        id: "2VfvaHIidPYwxXrLktmwu2",
        field: "linkText",
      })}
      linkUrl={Snippet({
        id: "2VfvaHIidPYwxXrLktmwu2",
        field: "linkUrl",
      })}
      extraText={Snippet({
        id: "5pS0T8WeC6Xp2O3i2UDudK",
        field: "text",
      })}
    />
  )
}

export default InstitutionForm
